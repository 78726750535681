import React from 'react';
import { District } from '../district';

export class Porto extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Porto', props.data)
		}
	}

    render () {
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="130" cy="130" r="15" fill="#187795" />
				<text x="130" y="130"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('porto') } name="porto" d="M181.9,123.6c0,0.2,0,0.4,0,0.6c0,0.1-0.2,0.3-0.2,0.4c0,0.1,0,0.2,0,0.4c0,0.3,0,0.6-0.1,1
	c-0.1,0.6-0.2,1.2-0.2,1.9c0,0.3,0,0.6,0,0.9c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.5-0.1,1-0.1,1.4c-0.1,1.2-0.2,2.4-0.8,3.5
	c-0.2,0.5-0.2,1-0.3,1.5c-0.1,0.4-0.1,0.9-0.2,1.3c-0.1,0.5-0.1,1-0.3,1.5c-0.2,0.5-0.3,1-0.3,1.5c0,0.2,0,0.5,0,0.7
	c0,0,0.4,1.6,0.5,1.8c-0.1,0.1-0.2,0.2-0.5,0.2c-0.2,0-0.5,0-0.7,0c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3,0-0.4,0
	c-0.2,0-0.5-0.1-0.8-0.1c-0.4,0-0.7,0-1,0.1c-0.4,0-0.8,0.1-1.2,0.1c-0.3,0-0.6,0-0.9,0c-0.4,0-0.7-0.2-1.1-0.1
	c-0.4,0-0.8,0-1.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.1-0.3,0.3-0.5,0.4c-0.1,0.1-0.2,0.2-0.4,0.4c-0.1,0.1-0.3,0.3-0.5,0.5
	c-0.3,0.2-0.6,0.4-1,0.6c-0.4,0.2-0.6,0.5-1,0.8c-0.2,0.1-0.4,0.2-0.5,0.3c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0l0,0
	c-0.2,0.1-0.5,0.1-0.7,0.1c-0.3,0.1-0.6,0.1-0.9,0.1c-0.3,0-0.6-0.1-1-0.1c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.6-0.3-0.8-0.4
	c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.3-0.3-0.5-0.4c-0.4-0.2-0.9-0.2-1.3-0.3c-0.3,0-0.6-0.1-0.8,0c-0.2,0-0.4,0.1-0.5,0.2
	c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2,0.2-0.4,0.4-0.6,0.7c-0.1,0.1-0.2,0.1-0.3,0.2l-0.4,0.4c-0.2,0.1-0.4,0.3-0.6,0.4
	c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.5,0-0.7-0.1c-0.2,0-0.3-0.1-0.5-0.2
	c-0.2-0.1-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.3-0.1-0.6,0-0.8-0.1c-0.3-0.1-0.6-0.2-0.8-0.3c-0.5-0.1-1-0.2-1.6-0.2
	c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0-0.3,0.2-0.5,0.3c-0.4,0.3-0.8,0.6-1.1,1c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.1-0.3,0.3-0.4,0.5
	c-0.2,0.3-0.6,0.5-0.8,0.9c0,0.1-0.1,0.1-0.2,0.2l-0.3,0.3c-0.2,0.2-0.5,0.3-0.7,0.4c-0.4,0.2-0.9,0.4-1.3,0.4c-0.2,0-0.4,0-0.6-0.1
	c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.2-0.1-0.3-0.3-0.5-0.4
	c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2,0-0.4-0.1-0.6-0.2c-0.2,0-0.5,0-0.7,0c-0.2,0-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.1-0.6,0.2
	c-0.5,0.2-0.7,0.5-1.1,0.8c-0.3,0.3-0.7,0.5-1.1,0.7c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.4,0.1-0.5,0.2c-0.4,0.2-0.9,0.3-1.3,0.4
	c-0.5,0.1-1,0.2-1.5,0.3c-0.2,0-0.5,0-0.7,0c-0.2,0-0.4,0-0.5,0c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0,0,0
	c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.6,0.2c-0.1,0.2-0.2,0.4-0.4,0.7c-0.1,0.2-0.1,0.4,0,0.6
	c0.1,0.2,0.2,0.5,0.4,0.7c0.3,0.6,0.5,1.2,0.8,1.9c0.1,0.3,0.6,1.3,0.1,1.5c-0.2,0.1-0.5,0-0.6,0c-0.2,0-0.5,0.2-0.7,0.2
	c-0.6,0.2-1.3-0.3-1.7-0.7c-0.5-0.4-1-0.7-1.6-1c-0.5-0.2-1-0.3-1.3-0.8c-0.1-0.2-0.1-0.4,0.1-0.5c0.2-0.1,0.4-0.2,0.3-0.4
	c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.3-0.4-0.5-0.6c-0.4-0.4-1-0.3-1.3-0.7c-0.3-0.3-0.5-1-1.1-0.7c-0.2,0.1-0.2,0.3-0.4,0.4
	c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.4,0.2-0.4,0.5c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.5,0,0.7
	c0,0.2,0,0.5-0.1,0.6c-0.1,0.2-0.4,0.2-0.6,0.2c-0.2,0-0.4,0.1-0.5,0.3c-0.1,0.1-0.2,0.1-0.4,0c-0.2-0.1-0.3-0.1-0.5,0
	c-0.2,0.1-0.3,0.2-0.5,0.1c-0.2-0.1-0.4-0.4-0.5-0.5c-0.2-0.2-0.4-0.4-0.6-0.6c-0.2-0.1-0.3-0.1-0.5,0c-0.2,0.1-0.6,0-0.9-0.1
	c-0.2-0.1-0.3-0.5-0.3-0.7c0-0.2,0.1-0.4,0.1-0.6c0.1-0.3,0-0.6-0.1-0.8c-0.1-0.4-0.6-0.4-0.8-0.2c-0.1,0.1-0.2,0.2-0.3,0.4
	c-0.1,0.1-0.2,0.1-0.4,0.2c-0.3,0-0.6,0-0.9-0.1c-0.2-0.1-0.5,0-0.7,0.1c-0.3,0.2-0.3,0.4-0.4,0.7c-0.1,0.1-0.1,0.2-0.2,0.4
	c0,0.1-0.1,0.2-0.1,0.4c-0.1,0.3-0.2,0.4-0.5,0.5c-0.2,0-0.6,0.1-0.7,0.3c0,0.1,0,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.2
	c-0.2,0.1-0.5,0.2-0.8,0.3c-0.2,0.1-0.5,0.1-0.7,0c-0.3-0.1-0.5-0.2-0.8-0.1c-0.6,0.2-1.3,0.1-1.8-0.1c-0.3-0.1-0.5-0.2-0.7-0.4
	c-0.2-0.2-0.5-0.2-0.7-0.4c-0.2-0.1-0.4-0.2-0.6-0.3c-0.1,0-0.1,0-0.2,0c-0.2,0.1-0.2,0.5-0.4,0.6c-0.3,0.1-0.7,0-1.1,0
	c-0.2-0.1-0.4-0.1-0.7-0.2c0.1-2.1,0.1-6.8-0.2-8.3c-0.4-1.7-1.7-6.3-1-7.3c0.6-0.9,1.8-1.1,0-1.5c-1.7-0.4-1.8-0.3-2.2-1.9
	c-0.4-1.7-1.8-7-2-8c-0.2-1-0.8-5.5-1.2-6.7c-0.5-1.2-1.5-8.1-1.6-9.4c-0.1-1.2-1.6-5-2.5-6.2c-0.8-1.2-1.8-3.5-1.6-4.9
	c0.2-1.4,2.9-3.9,1.5-5.7c-1.1-1.5-2.1-4.4-2.8-6.3c1.4,0.3,2.9,0.8,4,1.8c0.5,0.5,0.9,1.1,1.3,1.6c0.2,0.4,0.7,0.5,1.1,0.7
	c0.3,0.2,0.6,0.3,1,0.5c0.3,0.2,0.7,0.2,1,0.5c0.5,0.1,0.9,0.4,1.3,0.7c0.6,0.5,1.3,0.7,2,1c0.7,0.4,1.3,0.6,2,1
	c0.6,0.3,1.1,0.7,1.6,1c0.5,0.3,1.1,0.4,1.7,0.7c0.6,0.2,1.2,0.5,1.7,0.8c0.5,0.2,0.7,0.6,1,1c0.3,0.4,0.6,0.9,0.8,1.4
	c0.2,0.5,0.1,1.1,0.1,1.6c0,0.6-0.2,0.9-0.6,1.3c-0.3,0.4-0.4,0.8-0.2,1.3c0.2,0.5,0.7,0.9,1,1.3c0.3,0.5,0.6,1.1,1.2,1.3
	c0.5,0.2,1.1,0,1.6-0.1c0.6-0.1,1.2,0,1.7-0.2c0.2-0.1,0.4-0.2,0.5-0.3c0.3-0.1,0.6-0.1,0.9-0.2c0.2-0.1,0.4-0.1,0.6-0.2
	c0.2-0.1,0.3-0.1,0.5-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.7-0.3,1.5-0.6,2.3-0.6c0.6,0,1.2,0.2,1.9,0.2c0.4,0,0.7-0.1,1.1-0.1h1.1
	c0.4,0,0.7,0,1.1-0.1c0.4-0.1,0.7-0.3,1.1-0.4c0.2-0.1,0.4-0.1,0.5-0.1c0.2,0,0.4-0.2,0.6-0.2c0.3-0.1,0.7-0.1,1-0.2
	c0.7-0.1,1.4-0.1,2.1-0.1c0.4,0,0.8,0,1.2,0.2c0.4,0.1,0.6,0.3,0.9,0.6c0.4,0.4,1,0.7,1.5,0.9c0.5,0.2,0.9,0.2,1.5,0.4
	c0.7,0.1,1.3,0.4,2.1,0.4c0.4,0,0.7,0,1-0.1c0.4,0,0.7,0,1.1-0.1c0.6-0.1,1-0.4,1.6-0.3c0.6,0.1,1.2-0.2,1.7-0.3
	c0.4-0.1,0.8-0.2,1.2-0.5c0.2-0.3,0.4-0.6,0.6-0.9c0.3-0.4,0.7-0.8,1.1-1.2c0.4-0.3,0.6-0.7,0.9-1.1c0.3-0.4,0.6-0.8,1-1.1
	c0.3-0.2,0.6-0.4,1-0.6c0.5-0.2,0.9-0.3,1.4-0.3c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0,0.6,0c0.5,0.2,0.9,0.6,1.3,0.8
	c0.4,0.3,0.7,0.8,1.2,1.1c0.4,0.2,0.7,0.5,1,0.8c0.4,0.4,0.7,0.8,1.1,1.2c0.2,0.2,0.5,0.4,0.7,0.6c0.2,0.2,0.4,0.4,0.6,0.6
	c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.2,0.3,0.4,0.5,0.6c0.2,0.4,0.6,0.5,0.9,0.8c0.4,0.3,0.6,0.4,1.1,0.4c0.9,0.1,1.8,0,2.7,0.1
	c0.9,0.1,1.4,1.1,2.1,1.6c0.8,0.6,1.4,1.4,2.3,2c0.4,0.3,0.8,0.5,1.2,0.8c0.2,0.2,0.4,0.4,0.7,0.4c0.2-0.1,0.4-0.2,0.6-0.4
	c0.5-0.6,1-1.1,1.5-1.7c0.2-0.2,0.5-0.4,0.7-0.5c0.3-0.2,0.5-0.4,0.8-0.6c0.9-0.7,1.7-1.5,2.3-2.5c0.2-0.3,0.4-0.4,0.6-0.3
	c0.1,0,0.3,0.1,0.4,0.2c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.2,0.2,0.3,0.4,0.5c0.1,0.1,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.4,0.4
	c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.4-0.2,1.3,0.1,1.6c0.2,0.2,0.4,0.2,0.7,0.2c0.4,0.1,0.8,0.4,1.1,0.6
	c0.4,0.2,0.9,0.5,1.3,0.8c0.2,0.1,0.4,0.3,0.6,0.5c0.1,0.1,0.1,0.3,0.2,0.5c0.2,0.5,0.2,0.8,0.2,1.2c0,0.5-0.3,0.8-0.3,1.2
	c0,0.4-0.1,0.9,0,1.3c0.1,0.4,0.2,0.8,0.4,1.2c0.3,0.6,0.7,1,1.2,1.3C180.9,122.8,181.5,123.1,181.9,123.6z">
        <title>Porto</title>
    </path>
	{CircledNumber}
	</g>
            )
    }
}