import React from 'react';
import { District } from '../district';

export class Portalegre extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Portalegre', props.data)
		}
	}

    render () { 
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="210" cy="415" r="15" fill="#187795" />
				<text x="210" y="415"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('portalegre') } name="portalegre" d="M276.1,460.6l0.2-3.5l8.4-10.7l-1-2.9l0.9-2.7c0,0-2.7-2.6-2.8-2.6c-0.1,0,0.4-2.7,0.2-2.7
	c-0.1,0-4.5-4.5-6.5-5.3c-2-0.7-7.8,2.1-7.8,2.1s-2.4-2.1-2.4-2.2c0-0.1-0.4-7.1-0.4-7.1c-2.8-0.3-4-2.1-7.2-3.7
	c-3.1-1.6-4.6-7.6-4.6-7.6l1-4.5c0,0-4.8-5-6.4-6.4c-1.6-1.5-2.6-5.2-2.9-6.9c-0.2-1.8,2.2-3.6,2.7-4.6c0.5-1-0.3-1.8-0.8-2.3
	c-0.6-0.5,0-1,0.4-2.1c0.4-1.1-0.4-3-0.6-3.9c-0.2-0.9-0.8-1.3-1.9-1.5c-1.1-0.1-4-3-4.8-3.6c-0.7-0.6-1.3-1.5-1.9-2.8
	c-0.6-1.3-2.2-3.1-6-5.2c-3.8-2.1-3.7-4.7-4.1-6.1c-0.4-1.3-4.5-6.9-5.6-8.3c-0.7-0.9-0.3-1.3,0.4-1.5c-2.5-1.3-6.2-0.1-7,0.6
	c-1.1,0.8-5.2,2.7-6.9,2.9c-1.7,0.2-2.8-1.3-4.2-1.2c-1.4,0.2-5.1,5-5.7,6.1c-0.5,1.1-1.6,1.1-3.7,2.5c-0.8,0.6-1.4,0.7-1.9,0.7
	l-7.6,6.8c0,0-9.7-7.4-10.7-7.7c-1-0.4-5.7,9.6-6.4,10.9c-0.7,1.2-0.5,1.7-0.7,2.6c-0.3,0.8,2.4,4.1,2.9,4.8
	c0.5,0.7,1.3,1.7,1.7,2.3c0.4,0.6-0.3,3-0.4,4.2c-0.1,1.1-6.4,2.8-7.1,3.2c-0.6,0.4-1.5,6.4-1.7,7.6c-0.2,1.2-5.1,2.9-7.1,3.5
	c-2.1,0.6-3.5,2.2-4.2,3.1c-0.7,0.9-5.8,7.3-6.9,8.6c-1.2,1.3-5,0.9-6.2,1.1c-1.2,0.2-8.7,9.2-8.7,9.2l4.7,6.7c0,0,7.7,10,8.5,11
	c0.1,0.1,0.2,0.3,0.2,0.5c1.1-0.6,2.7-2.3,3.6-3.3c1-1,5.9,0,7,0c1.1,0,0.4,2.9,0.3,4c0,1.1,1.9,3.8,1.9,3.8s2.8-5.3,3.1-6.4
	c0.4-1.1,1.2-0.1,1.9,0.6c0.7,0.7,4.6,3.3,5.9,4.1c1.3,0.8,1.1,1.7,1,4.1c-0.1,2.4,4.5-0.2,5.9-0.5c1.5-0.3,0.9,0.1,2.7,0.7
	c1.8,0.6,2.2,3.4,2.5,4.3c0.4,0.9,0.9,0.8,1.6,1.1c0.7,0.3,3.4,0.9,5.2,1.5c1.8,0.6,2.5,0.5,4.8,0.7c2.3,0.2,3.6-2.5,4.5-3.5
	c0.9-1,1.6-0.1,2,0.6c0.5,0.6,1.9,1.4,2.5,1.4c0.6,0,0-2,0.5-2.6c0.5-0.6,1.5,0.2,2,0.5c0.6,0.3,1.4,1.4,3.3,1.6
	c1.9,0.1,2.3-2.2,2.5-3.2c0.2-1.1,4.1-1.5,6-1.7c1.9-0.2,2.3-1.6,2.8-2.7c0.6-1.1-1.1-2.7-1.7-3.4c-0.5-0.8,1.6-1.5,2.7-2
	c1.1-0.5,3.5,1.4,4.5,1.9c0.9,0.5,2.4,5.8,2.8,6.9c0.4,1.1,1.7,2.9,3.3,5.4c1.6,2.5,1.3,2.7,1.8,4.1c0.5,1.4,0.6,3.7,0.5,5.9
	c-0.1,2.2,0.8,3.4,2.9,3.6c2.1,0.2,3.3,1.6,4.6,3.5c1.2,1.9,6.7-1.2,8.1-2.3c1.5-1.2,3.7-1.2,5.4-1.2c1.7,0,2.4,3.3,2.4,3.3
	l-5.7,5.5l4.2,4.1h0c1-0.6,2.6-0.9,4.9-1.8c3.7-1.5,4.7-5.3,5.9-7.6c1.2-2.3,4.7-2.1,6.5-2.1c1.8,0,2.4-0.7,2.9-2
	c0.6-1.3,2.5-2.2,3.5-3.1c1-0.9-1.9-3.2-2.1-4C274.5,461.2,276.1,460.6,276.1,460.6z M165.4,414.1c-0.1,0.4-0.2,0.8-0.4,1.3
	c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.4-0.4,0.7-0.6,1c-0.2,0.3-0.5,0.5-0.7,0.8c-0.2,0.4-0.3,0.8-0.5,1.1c-0.2,0.3-0.4,0.6-0.6,0.9
	c-0.3,0.5-0.6,0.9-0.8,1.4c-0.1,0.2-0.2,0.5-0.4,0.7c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.3,0.1,0.5,0.3,0.7c0.1,0.1,0.2,0.2,0.2,0.4
	c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0.1-0.3,0.1-0.4,0.2c-0.3,0.1-0.5,0.2-0.8,0.4c-0.2,0.1-0.5,0.2-0.6,0.4
	c-0.3,0.3-0.5,0.7-0.7,1c-0.2,0.3-0.4,0.6-0.6,0.8l-0.3,0.4c-0.1,0.2-0.3,0.3-0.4,0.4c-0.2,0.4-0.5,0.7-0.7,1.1
	c-0.1,0.3-0.2,0.4-0.4,0.7c-0.1,0.2-0.2,0.5-0.4,0.7c-0.2,0.3-0.5,0.7-0.8,1c-0.1,0.1-0.3,0.2-0.5,0.4c-0.1,0.2-0.3,0.3-0.4,0.5
	c-0.2,0.2-0.3,0.3-0.3,0.5c-0.1,0.2-0.1,0.5-0.2,0.7c0.1,0,0.1,0.3,0.2,0.4c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.2,0.1,0.4-0.1,0.5
	c-0.2,0.2-0.5,0.4-0.7,0.3c-0.4,0-0.7-0.1-1-0.1h-0.1c-0.3-0.1-0.6-0.4-0.8-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2-0.1-0.3-0.2-0.5-0.5
	c-0.1-0.2-0.1-0.6,0-0.9c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.2-0.2,0.4-0.4c0.3-0.3,0.5-0.6,0.8-0.9c0.3-0.3,0.6-0.6,1-0.8
	c0.5-0.4,0.8-1.1,1.1-1.6c0.2-0.3,0.4-0.5,0.6-0.7c0.3-0.3,0.5-0.6,0.7-0.9c0.2-0.3,0.4-0.6,0.6-1c0.1-0.2,0.3-0.4,0.5-0.6
	c0.3-0.4,0.5-0.8,0.8-1.2c0.3-0.5,0.7-0.8,1.1-1.3c0.2-0.3,0.4-0.7,0.7-1c0.2-0.2,0.4-0.5,0.6-0.7c0.5-0.5,0.8-1,1.2-1.6
	c0.2-0.4,0.5-0.7,0.6-1.1c0.1-0.3,0.3-0.6,0.6-0.9c0.2-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.4-0.5,0.6-0.7c0.1-0.2,0.3-0.4,0.5-0.6
	c0.1-0.1,0.3-0.2,0.4-0.3c0.4-0.4,0.6-0.9,0.8-1.4c0.1-0.2,0.1-0.4,0.2-0.5c0.1-0.1,0.2-0.2,0.2-0.4c0.2-0.1,0.4-0.1,0.4,0.1
	C165.5,413.7,165.4,414,165.4,414.1z M188.4,426.3c-0.9-0.2-2,0-2.6,0.7c-0.4,0.4-0.5,0.9-0.8,1.4c-0.2,0.4-0.5,0.7-0.8,1
	c-0.2,0.3-0.5,0.5-0.7,0.8c-0.3,0.5-0.7,0.9-1.2,1.3c-0.5,0.4-1,0.9-1.3,1.3c-0.2,0.4-0.6,0.9-0.5,1.3c0.1,0.4,0.7,0.3,1,0.3
	c0.5,0,1.1,0,1.6,0c0.6,0,1.2,0,1.8,0c0-0.1,0.4-0.1,0.5-0.1c0.2,0,0.5,0,0.7,0c0.3,0,0.6,0,1,0.1c0.5,0,0.9,0.1,1.3,0.2
	c0.4,0,0.7,0.1,1.1,0.2c0.3,0.1,0.7,0.1,0.7,0.5c0,0.4-0.4,0.6-0.7,0.6c-0.4,0-0.8-0.3-1.2-0.4c-0.5-0.1-1-0.2-1.4-0.3
	c-0.5,0-1.1,0-1.6,0c-0.5,0-1.1,0-1.6,0c-0.6,0-1.2,0-1.8,0c-0.6-0.1-1.1,0-1.6,0.3c-0.5,0.2-0.9,0.5-1.4,0.7
	c-0.5,0.3-0.9,0.5-1.3,0.9c-0.2,0.1-0.4,0.4-0.6,0.5c-0.1,0.1-0.4,0.2-0.5,0.3c-0.6,0.5-1.1,1.1-1.6,1.7c-0.4,0.6-0.9,1.1-1.3,1.7
	c-0.5,0.6-1,1.1-1.6,1.6c-0.5,0.3-1.1,1-1.7,1c-0.3,0-0.5-0.1-0.8-0.3c-0.2-0.1-0.5-0.3-0.7-0.5c-0.4-0.5,0.2-1.1,0.5-1.4
	c0.5-0.5,1.1-0.7,1.6-1.2c0.3-0.2,0.5-0.5,0.6-0.9c0.1-0.3,0.1-0.6,0.2-0.9c0.2-0.6,0.6-1,1.2-1.2c0.2-0.1,0.5-0.2,0.7-0.4
	c0.4-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.2,1-0.4c0.2-0.1,0.4-0.2,0.6-0.3c0.1-0.1,0.3-0.1,0.5-0.3c0.1-0.1,0.2-0.2,0.3-0.4
	c0.2-0.3,0.4-0.6,0.6-1c0.1-0.1,0.2-0.3,0.4-0.5c0.1-0.1,0.2-0.3,0.3-0.4c0.6-0.6,1.3-1,1.8-1.7c0.5-0.7,1-1.3,1.4-2
	c0.2-0.3,0.4-0.5,0.7-0.7c0.2-0.1,0.4-0.3,0.5-0.4c0.4-0.3,0.6-0.7,1-1.1c0.1-0.1,0.2-0.2,0.4-0.4c0.1-0.2,0.2-0.4,0.4-0.6
	c0.2-0.4,0.4-0.9,0.6-1.3c0.3-0.9,0.6-1.8,1-2.7c0.4-0.7,0.9-1.5,1.3-2.2c0.5-0.7,0.9-1.5,1.2-2.2c0.4-0.7,0.7-1.5,1.3-2.1
	c0.1-0.1,0.4-0.3,0.6-0.2c0.2,0.1,0.2,0.3,0.2,0.5c0,0.7-0.4,1.5-0.7,2.1c-0.3,0.7-0.7,1.4-0.9,2.1c-0.2,0.6-0.4,1.2-0.7,1.8
	c-0.3,0.5-0.6,1-0.9,1.5c-0.1,0.4-0.2,0.8,0,1.1c0.2,0.3,0.6,0.3,1,0.3c0.6,0,1.1,0.1,1.6,0.4c0.3,0.2,1,0.6,0.9,1.1
	C190.9,427.6,188.8,426.5,188.4,426.3z M263.9,444.6c-0.6,0.5-1,1-1.5,1.5c-0.4,0.4-0.7,0.7-1.3,0.8c-0.5,0.1-1.2,0.2-1.5-0.3
	c-0.3-0.6,0.1-1.2,0.6-1.6c0.2-0.1,0.5-0.3,0.5-0.5c0-0.2-0.2-0.4-0.3-0.6c-0.5-0.4-1.1-0.4-1.6-0.2c-0.6,0.3-1.3,0.4-1.9,0.7
	c-0.3,0.2-0.6,0.4-0.8,0.5c-0.2,0.1-0.5,0.1-0.7,0.1c-0.2-0.1-0.4-0.2-0.5-0.5c-0.2-0.3-0.4-0.6-0.4-1c0.1-0.6,0.9-1.1,0.7-1.8
	c-0.1-0.6-0.8-0.5-1.2-0.4c-0.4,0-0.7,0-1.1,0c-0.6,0-1.6,0.2-1.9-0.5c-0.3-0.7-0.1-1.7,0.7-2c0.7-0.3,1.7-0.2,2.5-0.1
	c0.9,0.1,1.6,0.6,2.4,0.8c0.8,0.2,1.1-0.8,1.9-0.7c0.4,0.1,0.7,0.5,1,0.8c0.3,0.3,0.5,0.7,0.9,0.9c0.8,0.4,1.6-0.3,2.4-0.4
	c1-0.1,0.9,1.2,0.7,1.8c0.5,0.2,1.2,0.4,1.4,1C265.1,443.6,264.4,444.2,263.9,444.6z"><title>Portalegre</title></path>
	{CircledNumber}
	</g>
            )
    }
}