import React from 'react';
import { District } from '../district';

export class Braganca extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Bragança', props.data)
		}
	}
    render () { 
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="300" cy="80" r="15" fill="#187795" />
				<text x="300" y="80"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('braganca') } name="bragança" d="M360.1,80.9c-0.6,1.2-3,6.2-4.1,7.8c-1.1,1.6-3.4,4.9-3.8,6.2c-0.4,1.3-3.3,6.7-5,7.9c-1.7,1.1-3.5,2.3-4.5,2.7
	c-1,0.4-1.1,0.7-1.2,1.4c-0.1,0.7-0.5,3.9-1.9,5c-1.4,1.1-4.6,1.7-4.6,3.7c0,1.9,0,3-1.1,3c-1.1,0.1-2.5-0.1-3.7,0.7
	c-1.2,0.7-2.8,1.9-3.8,1.9c-1-0.1-1.9,0-2.5,0.8c-0.6,0.8-2.8,3-5.1,3.1c-2.4,0.1-5.4-0.1-6.5,2c-1.1,2.1-4.6,7.9-6.2,9.1
	c-1.6,1.1-3.3,3.5-3.3,5.4c-0.1,1.8-1,6.1-2.1,7.4c-1.1,1.4-2.1,2.4-2.5,3.3c-0.4,1-2.1,3.6-4.7,4.4c-2.5,0.7-3.8,2.2-8.8,1.9
	c0,0-1-0.2-1.6,0.1c-0.1-0.4-0.8-0.7-0.8-0.7c-0.2,0-0.5-0.1-0.7-0.2c-0.2-0.1-0.5-0.2-0.7-0.3c-0.5-0.2-0.9-0.6-1.4-0.7
	c-0.2-0.1-0.3-0.1-0.5-0.1h-0.4c-0.3,0-0.6,0-0.9,0c-0.3,0-0.5-0.1-0.8-0.1c-0.3,0-0.6,0-0.8,0c-0.3,0-0.5-0.1-0.7-0.2
	c-0.2-0.1-0.3-0.4-0.4-0.6c-0.2-0.5,0-1.1-0.4-1.4c-0.3-0.4-0.8-0.4-0.9-0.9c-0.1-0.6-0.4-1.1-0.7-1.7c-0.1-0.3-0.3-0.6-0.5-0.8
	c-0.2-0.3-0.5-0.6-0.8-0.8c-0.2-0.2-0.5-0.4-0.7-0.4c-0.7-0.1-1.5,0.1-2.1,0.5c-0.3,0.3-0.6,0.6-1.1,0.6c-0.4,0-0.8-0.1-1.1-0.4
	c-0.3-0.2-0.3-0.6-0.4-0.9c-0.1-0.6-0.2-1.2-0.2-1.8c0-0.6,0-1.2,0.1-1.7c0.2-1.4,0.2-2.8,0-4.2c-0.1-0.8-0.5-1.8-1.4-2
	c-0.6-0.1-1.2,0.2-1.7,0.4c-0.5,0.1-0.8,0.1-1.1-0.4c-0.1-0.3-0.2-0.8-0.5-1c-0.3-0.2-0.8,0-1.1,0.1c-0.9,0.2-1.5,0.9-2,1.7
	c-0.3,0.4-0.5,0.8-0.9,1.2c-0.4,0.3-0.8,0.4-1.2,0.4c-0.5,0-1,0-1.4,0c-0.4,0-0.7-0.1-1.1-0.2c-0.2-0.1-0.4-0.2-0.6-0.2
	c-0.1,0-0.2,0-0.4-0.1c-0.8-0.4-1.6-0.8-2.4-1.1c-0.7-0.2-1.4-0.6-2.2-0.8c-0.3-0.1-0.7-0.1-1-0.1c-0.3,0-0.6,0-0.9,0
	c-0.2,0-0.5-0.1-0.7-0.1c-0.4,0-0.7,0-1,0c-0.1,0-0.2,0-0.2,0c-0.6,0-1.1,0-1.6,0c-0.4,0-0.8,0-1.2-0.1c-0.3,0-0.6,0-0.9-0.1
	c-0.3,0-0.6-0.1-0.9-0.2c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.2-0.2-0.4-0.4c-0.2-0.2-0.3-0.6-0.5-0.8c-0.2-0.5-0.5-1-0.7-1.5
	c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.4c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.5-0.5-1.2-0.9-1.8-1.2
	c-0.3-0.1-0.6-0.1-0.8-0.2c-0.4-0.1-0.8-0.1-1.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.1,0-0.2,0.1-0.4c0.1-0.3,0.1-0.6,0.2-0.9
	c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.2-0.9,0.3-1.3c0-0.1,0-0.1,0.1-0.2c0.1-0.4,0.3-0.8,0.4-1.2
	c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.4,0.2-0.8,0.3-1.2c0.2-0.9,0.4-1.9,0.7-2.8c0.1-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.1-0.5,0.2-0.7
	c0.1-0.3,0.1-0.6,0.1-0.9c0.1-0.2,0.1-0.5,0.2-0.8c0.1-0.2,0.2-0.2,0.4-0.4c0.2-0.1,0.2-0.4,0.4-0.6c0.1-0.1,0.3-0.3,0.5-0.4
	c0.2-0.1,0.5-0.1,0.6-0.3c0.1-0.1,0.2-0.2,0.4-0.2c0.2-0.1,0.4-0.1,0.6-0.3c0.2-0.1,0.4-0.2,0.5-0.4c0.4-0.2,0.8-0.4,1.2-0.8
	c0.5-0.5,1-1.1,1.2-1.8c0.2-0.9-0.2-1.6-0.7-2.3c-0.2-0.4-0.4-0.7-0.8-1c-0.3-0.2-0.7-0.4-1.1-0.7c-0.4-0.3-0.8-0.5-1.1-1
	c-0.1-0.2-0.1-0.3-0.1-0.5c0-1.2-0.1-2.3-0.1-3.5c0-0.3,0-0.7-0.1-1c-0.1-1.2-0.1-2.4,0-3.6c0-0.6-0.1-1.1,0-1.7
	c0-0.3,0.1-0.6,0.1-0.8c0.1-0.2,0.2-0.4,0.5-0.5c0.3-0.2,0.6-0.2,1-0.4c0.2-0.1,0.5-0.2,0.7-0.3c0.2-0.1,0.5-0.2,0.7-0.2
	c0.2,0,0.4-0.1,0.6-0.1c0.4-0.2,0.8-0.5,1.1-0.9c0.2-0.3,0.2-0.6,0.2-1c0-0.4,0.1-0.8,0.1-1.1c0-0.2,0-0.4,0.1-0.5
	c0.1-0.4,0.2-0.8,0.4-1.1c0.4-0.7,0.7-1.5,0.8-2.3c0.1-0.7,0.7-0.9,1.3-1c0.2-0.1,0.5-0.1,0.7-0.2c0.3-0.1,0.5-0.2,0.8-0.2
	c0.7,0,1.5,0,2.2,0c0.6,0,1.2,0.1,1.7-0.2c0.2-0.1,0.4-0.3,0.5-0.6c0.1-0.4,0-0.9,0.1-1.3c0-0.1,0.1-0.2,0.2-0.4
	c0.1-0.2,0.1-0.4,0.1-0.6c-0.1-0.4-0.4-0.7-0.6-1c-0.2-0.4-0.4-0.8-0.5-1.2c-0.2-0.6-0.2-1.2-0.5-1.8c-0.1-0.4-0.4-0.7-0.5-1.1
	c-0.1-0.3,0-0.6,0-0.9c0-0.2-0.1-0.4,0-0.6c0.1-0.5,0.4-1,0.5-1.6c0.1-0.6,0.4-1.1,0.6-1.7c0.1-0.2,0.3-0.5,0.4-0.7
	c0.1-0.3,0.4-0.5,0.5-0.8c0.1-0.4,0.2-0.9,0.4-1.3c0.1-0.3,0.1-0.5,0.3-0.7c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.6,0.5-1.2,0.6-1.8
	c0.1-0.3,0.1-0.7,0.2-1c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.3,0.2-0.7,0.4-1c0.1-0.2,0.2-0.4,0.3-0.6
	c0.1-0.1,0.1-0.3,0.1-0.5c0.1-0.4,0.2-0.8,0.3-1.2c0-0.2,0-0.5,0-0.7c0-0.4,0.1-0.7,0.3-1c0.2-0.5,0.2-1.1,0.3-1.7
	c0-0.2,0.1-0.5,0.3-0.7c0.2-0.4,0.3-0.7,0.5-1.1c0.1-0.5,0.3-0.9,0.5-1.3c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.2-0.4
	c0.1-0.1,0.2-0.2,0.4-0.4c0.1-0.1,0.1-0.3,0.2-0.5c0.2-0.2,0.2-0.6,0.2-0.9c0-0.4,0.2-0.8,0.1-1.1c-0.1-0.2-0.1-0.3-0.1-0.5
	c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.4-0.2-0.8-0.3-1.2c-0.1-0.3-0.2-0.7-0.4-1c-0.2-0.3-0.4-0.5-0.6-0.8c-0.1-0.1-0.2-0.3-0.4-0.4
	c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1-0.2-0.3-0.4-0.5c-0.2-0.2-0.4-0.5-0.5-0.7c-0.3-0.4-0.5-0.8-0.7-1.2c-0.2-0.2-0.4-0.4-0.4-0.7
	c0-0.1,0.1-0.5,0-0.5c0-0.2,0-0.5-0.1-0.8c0-0.3,0-0.5-0.1-0.8c0-0.5,0-1-0.1-1.5c0-0.5,0-1-0.1-1.5c0-0.2,0-0.4,0-0.6
	c0-0.3-0.1-0.6-0.1-0.9c0,0,0-1.6-0.6-2c0.3-0.5,0.7-1.7,0.1-3.4c-0.9-2.5-2.4-6.8-1.2-8.1c1.1-1.4,2.9-2.2,2.9-2.2s7.5-0.5,8,1.3
	c0.5,1.7,1.2,3.8,1.2,3.8l6.1,0.1l3.9-3.6c0,0,1.3-0.9,2.1,0.3c0.9,1.2,3,4.9,3,4.9l1.7-0.1l1.6-1.3c0,0,2,1.6,2.1,1.6
	c0-0.1,5.2-0.6,5.2-0.6l2.1-1.5l-0.3-4.2c0,0,1.6-2,1.7-2c0.1,0,3.8,1.3,3.8,1.3l3.5,6l10.4,0.6l5.6-3.9c0,0,5.3,3.5,4.3,4.7
	c-1,1.2-2,2.1-2,3.8c0,1.6,0.2,4.2,1.5,4.2c1.3,0,2.1-0.5,2.7,0.1c0.6,0.6,1.5,0.9,0.9,1.8c-0.7,0.9-1.5,1.5-1.4,2.6
	c0.1,1.1,0.4,7.2-0.4,8.2c-0.8,1-3.3,5.1-2,7c1.3,1.9,6.2,7.8,6.7,8.4c0.6,0.5,2.4,3.3,3.1,2c0.7-1.3,1.3-2.1,2.3-2
	c1,0.1,8.7-0.2,8.8-0.2c0.1,0,11.2,4.6,11.2,4.6s0.8,2.1,1.4,2.8c0.6,0.7,4.7,4.8,5.8,5.4C360.6,79,360.7,79.5,360.1,80.9z">
        <title>Bragança</title>
    </path>
	{CircledNumber}
	</g>
            )
    }
}