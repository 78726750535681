import React from "react"
import { EventEmitter } from '../events/event-emitter';

export class District extends React.Component {
    numberOfPersons = (districtName, data) => (
        data.filter((element) => (
            element.district.toLowerCase().includes(districtName.toLowerCase())
            ), 0)
        ).length;

    clicked = (name) => {
        EventEmitter.publish('clickedDistrict', name);
    };
}