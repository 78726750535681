import React from 'react';
import { District } from '../district';

export class Beja extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Beja', props.data)
		}
	}
    render () { 
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="160" cy="620" r="15" fill="#187795" />
				<text x="160" y="620"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
		<g>	
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('beja') } name="beja" d="M285.2,559.4c0-0.1-1.7-1.6-1.7-1.6s-11.3,4.1-12.5,4.6c-1.2,0.6-2.5-0.5-3.5-1.5c-0.1-0.1-0.1-0.2-0.2-0.3
	c-1.3,1.1-3.9,1.7-4.6,1.9c-0.9,0.3-1.5,1.7-1.8,2.6c-0.4,0.9-0.6-1.4-0.9-1.7c-0.3-0.3-0.6-1-0.5-1.9c0-1-0.2-0.8-1-1.5
	c-0.7-0.7-0.5-2.3-0.3-3.6c0.2-1.3-2-2.1-3.3-2.6c-1.3-0.6-5.4-2.3-6.7-2.6c-1.3-0.3-0.6-4.5-0.5-5.1c0.1-0.6-0.6-1.3-1-1.5
	c-0.4-0.2-1-1.1-1.8-1.8c-0.8-0.7-1.5-0.3-2.4,0c-0.9,0.4-2.2,1-3.4,1.3c-1.1,0.3-2.4,0.9-2.9,1.2c-0.5,0.3-0.9,1.1-1.1,1.5
	c-0.2,0.4-1.6,2.1-2.1,2.6c-0.5,0.5-1.2,2.1-1.8,2.8c-0.6,0.8-1.6,1.5-2.4,1.8c-0.8,0.4-1.6,0.7-2.9,1.6c-1.3,0.9-4.5,2.8-6,3.8
	c-1.6,1-2.3,0.6-4.5,0.7c-2.2,0.1-3,0.2-4.1,0.5c-1.1,0.3-3.7-0.2-4.7-0.5c-0.9-0.3-5.1-1.4-7.5-1.9c-2.4-0.4-6.9-3.8-7.7-4.3
	c-0.8-0.5-3.1-2.2-3.8-2.3c-0.7-0.1-1.7,0.4-2.5,0.8c-0.8,0.5-13-7.8-14.2-8.6c-1.2-0.8-2.2-1.5-4.3-2.1c-2.1-0.5-2.4,1.1-3.2,1.7
	c-0.8,0.6-3.3-1.6-4-2.3c-0.7-0.7-10.1-0.4-10.1-0.4c0.2,0.2,0.4,0.6,0.4,0.9c0.1,1.4,1.1,8.8,1.3,10.3c0.2,1.5,0.1,1.7-1.1,2.4
	c-1.2,0.7-9.1,4.5-10,5c-1,0.5-6.4,2.7-6.4,4.2c0,1.5,0.5,3.7-0.7,5.5c-1.2,1.7-2.8,4.8-3.2,5.4c-0.4,0.6-0.9,1.5-0.8,1.9
	c0,0.5,0.6,2.7-0.2,3.4c-0.8,0.6-1.5,1.5-1.2,2.3c0.2,0.9,0.7,2,1.5,2.3c0.8,0.4,2.8,1.7,2.8,3.6c0,1.8,0.4,8.1,0.4,8.7
	c0,0.6-0.1,2.4,0.3,3.3c0.4,0.9,0.8,1.7,0.5,2.3c-0.3,0.6-0.8,2.3-0.8,3c0.1,0.6-0.3,1-1,1c-0.7,0-3.1,0.1-4.2,0.3
	c-1.2,0.2-2.2,0.5-3.3-0.2c-1.1-0.7-1.5-1.4-3.1-1.1c-1.6,0.3-5.6,0.6-8,0.8c-2.4,0.3-5.6,0.5-6.7,1.1c-1,0.6-1,0.7-1.9,1.1
	c-1,0.4-1.4,0.6-1.2,1.2c0.2,0.6,0.9,2.1,0.5,3c-0.4,0.9-2,3.5-2.4,4.7c-0.4,1.2-1.3,3.5-1.9,3.7c-0.6,0.1-5.2,0.6-5.9,0.1
	c-0.7-0.5-1.6-1.2-2.3-1.1c-0.8,0.1-1.6,0.8-2-0.1c-0.4-0.9-2.7-3.8-3.1-4.5c-0.4-0.7-1.5-1.8-1.7-2.2c-0.2-0.4-0.5-1.9-1.5-1.6
	c-1,0.3-2.9,1.2-3.8,0.8c-0.5-0.2-1.5-0.8-2.4-1.3c0,1-1,1.7-1.3,2.5c-0.4,0.9-0.8,3.9-0.8,5.3c-0.1,1.4,0.2,1.8,0.8,2.2
	c0.6,0.4,0.5,1.1,0.5,1.7c0,0.7,0.4,0.6,1.3,0.7c1,0.1,0,1.5-0.5,1.9c-0.5,0.5,0.1,0.7,0.2,3.5c0.1,2.8-0.4,3-1.3,3.8
	c-0.9,0.7-0.2,4-0.2,4.9c0.1,0.9-1.1,3.6-1.4,4.5c-0.2,0.9-0.1,1.4,0.2,1.9c0.3,0.5,0.2,0.9,0.2,1.7c0.1,0.8,0.7,1.8,1.3,2.5
	c0.6,0.6,1,5.5,1.2,6.6c0.3,1.1-1.5,4.1-2.1,5.6c-0.2,0.5-0.3,1.2-0.4,1.9c1.7,0.2,5.3,0.8,5.7,2.3c0.5,1.9,1.6,5.2,3.2,5.5
	c1.6,0.4,7.8,1.9,9.1,2.1c1.3,0.2,2.3,0.2,3-0.3c0.7-0.4,4.5-3.2,5.9-2.9c1.3,0.3,14.5,3,14.5,3l5.3-5.6c0,0,7.8,0.2,7.9,0.2
	c0.1,0,3.8-2.4,3.9-2.4c0.1,0,4,3.5,3.7,5.2c-0.3,1.8-1,4.1,0.6,4.5c1.6,0.4,13,3.8,14.4,4.2c1.4,0.4,7.8,2.7,8.9,2.3
	c1.1-0.4,10.2-4,8.1-5.1c-2-1-2.9-1.8-1.5-2.9c1.4-1.1,2.1-1.3,3.2-1.3c1.1,0,9.9,0.8,9.2-0.6c-0.7-1.4-0.2-1.6,0-2.5
	c0.3-1,0.4-2.2,1.1-2.2c0.8,0,2.9,0.7,3.3-0.3c0.4-1,1.1-2.3,2.3-2.5c1.1-0.2,9.3-1.7,11.5-3.5c2.3-1.8,3.5-3.4,5.2-2.9
	c1.6,0.6,6.1,2.5,6.9,1.7c0.9-0.8,3.4-3.9,4.7-3.7c1.3,0.2,3.4,0.8,4.4,0.2c0.8-0.5,2.8-1.5,4.5-2c-0.6-1.2-1.1-2.4-1.5-3.4
	c-1.3-3.4,8.5-19.9,8.5-19.9l0.6-7.1l14.4-13.4l6.6-19.6l12.8-2.3l3.1-4l6.5,2.6l5.2-2l-1.2-4C278.2,579.5,285.2,559.5,285.2,559.4z
	"><title>Beja</title></path>
	{CircledNumber}
	</g>
            )
    }
}