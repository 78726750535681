import React from 'react';
import { District } from '../district';

export class Santarem extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Santarém', props.data)
		}
	}

    render () {
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="110" cy="400" r="15" fill="#187795" />
				<text x="110" y="400"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('santarem') } name="santarém" d="M193.3,363.8l-7.6,6.8c0,0-9.7-7.4-10.7-7.7c-1-0.4-5.7,9.6-6.4,10.9c-0.7,1.2-0.5,1.7-0.7,2.6
	c-0.3,0.8,2.4,4.1,2.9,4.8c0.5,0.7,1.3,1.7,1.7,2.3c0.4,0.6-0.3,3-0.4,4.2c-0.1,1.1-6.4,2.8-7.1,3.2c-0.6,0.4-1.5,6.4-1.7,7.6
	c-0.2,1.2-5.1,2.9-7.1,3.5c-2.1,0.6-3.5,2.2-4.2,3.1c-0.7,0.9-5.8,7.3-6.9,8.6c-1.2,1.3-5,0.9-6.2,1.1c-1.2,0.2-8.7,9.2-8.7,9.2
	l4.7,6.7c0,0,7.7,10,8.5,11c0.1,0.1,0.2,0.3,0.2,0.5c0.1,1-1.1,2.3-1.4,2.9c-0.4,0.8-3.8,8.1-4.1,8.6c-0.2,0.4-1,1.6,1,2.4
	c2,0.7,7.7,3.4,8.6,3.4c0.9,0.1,0.6,0.2,0.7,1.1c0.2,0.8-0.4,1.5-0.8,2.2c-0.4,0.7,4.2,7.5,4.6,8.1c0.3,0.6,0.4,1-0.3,1.4
	c-0.7,0.3-1.8,1.9-2.4,2.3c-0.6,0.4-3.9-5.1-5-6.7c-1.1-1.6-7.5-4.7-8.7-5.3c-1.2-0.7-8.5-0.1-9.7,0c-1.2,0-1.5,0.4-1.9,0.8
	c-0.4,0.4-2.3,0.4-3,0.7c-0.7,0.3-2.1,0.8-2.6,1c-0.5,0.1,0.6,2.2,0.9,2.9c0.4,0.7-0.1,1.2-0.8,1.8c-0.7,0.6-1,1.3-1.2,2
	c-0.2,0.7-5.3-2.1-5.8-2.4c-0.5-0.3-0.5,2-0.5,2.7c0,0.8-2.8,0.7-4.6,0.9c-1.8,0.2-3.8-0.4-4.5-0.4c-0.7,0,1.1-4.2,1.8-5.8
	c0.7-1.6-1.7-1.2-2.9-1c-1.1,0.2-1.4,1.8-1.8,3.2c-0.4,1.4-7.7-4.3-8.4-4.8c-0.6-0.5-1.3,0-1.6,0.3c-0.2,0.4-2,1-2.9,1.1
	c-0.9,0.1-0.9,3.8-1.1,5c-0.2,1.3-0.8,4.8-0.8,5.8c0,1-1.7,1.5-2.5,2.2c-0.9,0.7-1.3,0-1.3,0s-3.4-2.7-4.1-3.4
	c-0.7-0.7-4.3,1.5-5.7,2c-1.5,0.5-2.4,0-2.8-0.2c-0.4-0.2-2.4-0.8-3.5-1.1c-0.5-0.1-1.2-0.4-1.9-0.6c0.5-0.5,0.9-1.1,0.9-1.6
	c0-1.2-1.6-3.1-1.5-4c0.1-0.8,1.1-1.3,1.1-2.1c0-0.7,0.6-2.9-0.2-1.7c-0.8,1.2-2.1,1.7-2,0.2c0.1-1.5,0.1-3.2,0.7-3.8
	c0.6-0.6,0.9-1.1,0.2-2.1c-0.7-1-1.6-3.2-1.5-4.2c0.1-1,1.2-3.9,1.9-5.4c0.7-1.4,3.3-4.7,3.7-5.8c0.5-1.1,2.2-3.6,1.4-3.9
	c-0.2-0.1-0.3-0.1-0.5-0.2c3.2-3.6,7.6-2.2,7.6-2.2s5.3-3.9,6.7-5.1c1.4-1.3-2.6-5.5-3.2-6c-0.6-0.6-2.3-2.6-3.6-4.4
	c-1.3-1.8-6-7.5-6.4-8.1c-0.4-0.6,6.7-0.8,7.9-0.7c1.2,0.1,1.7-2.2,2.2-3.1c0.4-1-2.4-3.5-2.8-4c-0.4-0.5-1.3-0.7-2.3-0.7
	c-1.1,0-6.5-0.2-7.6-0.3c-1.1-0.1-2.5-0.1-3.8-0.2c-1.3-0.2-0.6-2.3-0.2-4.1c0.4-1.8-1.7-2.1-2.9-2.3c-0.8-0.1-1.7-1.6-2.3-2.5
	c1.8-0.1,3.2-0.9,3.2-2.2c0-1.8-0.4-7,0.3-7.3c0.7-0.3,1.7-1.7,2.9-1.9c1.2-0.2,3.7-1.6,4.2-2.5c0.5-1,2.6-6.6,3.5-6.9
	c1-0.4,4.4-2,5.9-2.2c1.5-0.1,6.5-1.1,7.8-1.1c1.4,0,2.9,0.3,3.3-1c0.4-1.3,1-4.6,1.6-4.6c0.6,0,4,0.8,4.2,0
	c0.2-0.8,1.2-4.5,0.4-6.4c-0.9-1.9-4.2-6.5-2-8c2.3-1.5,5-2.9,5.5-4.4c0.5-1.5,0.7-2.1,1-2.5c0.3-0.5,0.7-1.4,0-2.3
	c-0.7-0.9-2.4-3.4-2.5-4.7c0-1.3-0.2-4.8-0.2-4.8s-0.2-1,1.2-1.4c1.5-0.5,8.1-1.8,9.3-2.6c1.2-0.7,0.6-1.7,3.3-2.5
	c2.7-0.8,3-1.5,4.1-1.9c1.1-0.4,3.1-1.9,3.7-0.4c0.6,1.5,2.8,10.5,2.8,10.5s10.5-2,12.2-2.1c1.8-0.1,3.3,0.2,3.4-0.9
	c0.1-1.1,0.2-2.4,1-2.5c0.3,0,1.3-0.1,2.3-0.2c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.6-0.2,1.4-0.1,2.1c0,0.4,0,0.7,0.1,1
	c0.1,0.5,0.1,1,0.1,1.4c0,0.4,0.2,0.8,0.4,1.1c0.1,0.1,0.1,0.3,0.2,0.5c0,0.2,0.2,0.4,0.2,0.5c0.4,0.4,0.8,0.7,1.1,1.1
	c0.2,0.3,0.6,0.4,1,0.5c0.4,0.1,0.7,0.3,1,0.5c0.3,0.1,0.6,0.2,0.9,0.3c0.3,0.1,0.7,0.2,0.8,0.5c0.2,0.3,0.2,0.7,0.2,1
	c0.1,0.4,0.1,0.8,0.2,1.2c0.1,0.8,0.1,1.6,0.1,2.5c-0.1,1.5-0.9,2.8-0.9,4.2c0,0.4-0.1,0.8-0.1,1.2c0,0.4,0.1,0.7,0.1,1.1
	c-0.1,0.7-0.7,1.2-1.2,1.6c-0.5,0.4-1,0.8-1.5,1.1c-0.2,0.1-0.5,0.3-0.7,0.5c-0.2,0.1-0.5,0.2-0.8,0.3c-0.2,0-0.5,0-0.7,0.2
	c-0.4,0.2,0,0.9,0,1.2c0.1,0.4,0,0.7,0,1.1c0,0.7-0.5,1.3-1,1.9c-0.4,0.5-0.7,1.1-0.9,1.8c-0.1,0.2-0.1,0.5-0.2,0.8
	c-0.1,0.4-0.3,0.8-0.5,1.2c-0.2,0.4-0.2,0.7-0.2,1.1c0,0.2,0,0.4,0,0.6c0,0.1,0,0.1,0.1,0.3c0,0.3-0.1-0.1-0.1,0
	c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.1,0.5,0.3,0.7c0.5,0.5,1.1-0.2,1.5-0.5c0.4-0.4,0.8-0.8,1.2-1.3c0.4-0.5,0.9-1,1.3-1.5
	c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.2-0.2,0.3-0.3c0.3-0.4,0.4-0.9,0.6-1.2c0.1-0.3,0.2-0.6,0.4-0.8
	c0.1-0.3,0.3-0.6,0.5-0.8c0.1-0.2,0.2-0.6,0.4-0.9c0.2-0.6,0.4-1.2,0.8-1.8c0.2-0.2,0.4-0.4,0.5-0.6c0.2-0.2,0.5-0.2,0.8-0.2
	c0.6,0,1.2,0,1.8-0.1c0.3-0.1,0.8-0.1,0.9-0.4c0.1-0.2-0.1-0.6-0.2-0.8c0-0.1,0-0.1,0-0.2c1,0.5,2.2,1.1,3.1,1.3
	c1.6,0.2,3.4,0.9,4,1.1c0.6,0.2,0.9,0.6,2.6,0c1.7-0.6,2.8-0.5,2.8-1.6c0-1.2,0.5-6.8,0.7-9.1c0.2-2.3,0-6,1-5.8
	c1.1,0.1,2.8,0.3,4.5,0.2c1.7-0.1,6.2,0,6.3,0.4c0.1,0.5,0.3,1.1,0.6,1.2c0.2,0.1,0.7,1.1,0.4,2.2c-0.3,1.1-0.5,3.5-0.6,3.8
	c0,0.3-0.3,0.9,0.6,1c0.9,0.1,3.1,0.7,4.1,0.7c1.1,0.1,2.4,0.7,2.8,1.2c0.4,0.6,3.9,5.7,4.7,6.8c0.7,1.2,3.8,6.5,5.3,6.5
	C191.9,363.4,192.5,363.8,193.3,363.8z"><title>Santarém</title></path>
	{CircledNumber}
	</g>
            )
    }
}