import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Map } from '../components/map'
import styled from 'styled-components'
import { ContentCards } from "../components/content-card"
import Azores from '../components/districts/islands/azores'
import Madeira from '../components/districts/islands/madeira'

import { graphql } from "gatsby"

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  @media (max-width: 760px) {
    grid-template-columns: 1fr;
  }
`

const MapWrapper = styled.div`
height: 80vh;
`

const MapContainer = styled.div`
display: grid;
grid-template-columns: [start] 1fr [middle] 1fr [end];
grid-template-rows: [start] 1fr [middle] 1fr [end];
height: 100%;
background-color: #ebf7f7;
`

const ContinentalPortugalMap = styled.div`
grid-row: start / end;
grid-column-start: middle;
border-left: 3px dotted #09586f;
text-align: center;
`

const AzoresContainer = styled.div`
margin-top: auto;
border-bottom: 3px dotted #09586f;
text-align: center;
`

const MadeiraContainer = styled.div`
text-align: center;
`

const MadeiraRegion = styled(Madeira)`
border-top: 3px dashed black;
&& g {
  fill:#083d4c;
  stroke:#FFFFFF;
  stroke-width:1;
  stroke-miterlimit:10;
  transition: fill 1s;
  
  :hover {
            fill: #95EF86;
            cursor: pointer;
		}
		
		&.disabled {
			opacity: 0.4;
			pointer-events: none;
		}
}
`

export const getPhysiotherapists = graphql`
query AllPeople {
  allPeopleCsv {
    nodes {
      name,
      phone,
      email,
      professionalCard,
      apfId,
      academics,
      workYears,
      conditions,
      specialty,
      conditionTypes,
      specialInterest,
      workPlace,
      district,
      actingRegions,
      links
    }
  }
}
`

class IndexPage extends React.Component {

  constructor(props) {
    super();
    this.state = {
      physiotherapists: props.data.allPeopleCsv.nodes,
      selectedRegion: {}
    }
  }

  render() {
  if (this.state.physiotherapists) {
    const data = this.state.physiotherapists;
    return (
      <Layout>
        <SEO title="Home" />
        <Columns>
        <ContentCards data={data} ref={instance => { this.content = instance; }}/>
        <MapWrapper>
          <MapContainer>
            <AzoresContainer>
              <Azores data={data} />
            </AzoresContainer>
            <ContinentalPortugalMap>
              <Map data={data} click={this.handleClick} />
            </ContinentalPortugalMap>
            <MadeiraContainer>
              <MadeiraRegion data={data} />
            </MadeiraContainer>
          </MapContainer>
        </MapWrapper>
        </Columns>
      </Layout>
    )
  }
  return(<React.Fragment>A carregar...</React.Fragment>);
  }
}

export default IndexPage