import React from 'react';
import { District } from '../district';

export class Setubal extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Setúbal', props.data)
		}
	}

    render () {
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="110" cy="540" r="15" fill="#187795" />
				<text x="110" y="540"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('setubal') } name="setúbal" d="M132.3,505c-4.5,4.7-10.7-1.2-10.7-1.2l-2.7,1.8c-18.2,3.3-11.3-9.3-11.8-11c-0.6-1.8,7.4-3.9,13.3-1.2
		c4.1,1.9,5.9,1.1,6.2-0.2v0c0.1-0.5,0-1.2-0.2-1.8c-0.9-1.9-1.5-2.5-2.4-6.5c-0.9-4-0.6-5.8-3.9-5.8c-0.1,0-0.2,0-0.4,0h0
		c-3-0.1-4.7-1.2-5.3-2.7c-0.6-1.4,1.4-4.8,1.6-5v0c-0.4-0.2-0.8-0.4-1.3-0.6c-1.4-0.6-2.8-1.4-3-1.6c-0.5-0.3-0.5,2-0.5,2.7
		c0,0.8-2.8,0.7-4.6,0.9c-1.4,0.2-2.8-0.1-3.8-0.3c-0.3-0.1-0.6-0.1-0.7-0.1c-0.7,0,1.1-4.2,1.8-5.8c0.7-1.6-1.7-1.2-2.9-1
		c-1.1,0.2-1.4,1.8-1.8,3.2c-0.3,1.4-7.7-4.3-8.3-4.8s-1.3,0-1.6,0.3c-0.2,0.4-2,1-2.9,1.1c-0.9,0.1-0.9,3.8-1.1,5
		c-0.2,1.3-0.8,4.8-0.8,5.8c0,1-1.7,1.5-2.5,2.2c-0.8,0.7-1.3,0-1.3,0s-3.4-2.7-4.1-3.4c-0.7-0.7-4.3,1.5-5.7,2
		c-1.5,0.5-2.4,0-2.8-0.3c-0.4-0.2-2.4-0.8-3.5-1.1c-0.5-0.1-1.2-0.4-1.9-0.6c-0.6,0.6-1.4,1.1-1.9,1.4c-1,0.5-1.7-0.4-2.3-0.4
		c-0.7-0.1-3,0.8-4,1.8c-0.9,1-1.8,1.7-3.1,2.5c-1.4,0.8-1,2.1-0.6,3.2c0.3,1.1,1.7,0.1,2.5-0.3c0.8-0.4,1.4,0.3,1.9,0.6
		c0.5,0.3,0.7-0.4,1.7-1c1-0.7,1.7,0.5,2,1.1c0.3,0.6,1.2,0.4,2.4,0.3c1.2-0.1,1.2,0.6,0.2,0.5c-1-0.1,0.1,0.6,0,1.1
		c-0.1,0.5-1.4-0.4-2.7-0.8c-1.3-0.3-2.7,0-3.1,1.3c-0.4,1.3,2.3,2.5,1.7,2.9c-0.5,0.4-2-0.4-2.8-0.9c-0.8-0.4-1.4,0.2-0.8,1.7
		c0.6,1.4-1.2-0.5-1.4-1.1c-0.3-0.6-1.6-0.6-2.5-0.6c-0.8,0-1,0.9-2.6,1.2c-1.6,0.4-0.9,1.6-0.9,2.4c0,0.8,1.2,0.5,2.3,1
		c1.1,0.5,1,1.7,1,2.2c-0.1,0.4,0.7,1.3,0.7,3.1c0,1.8-0.7,0.3-1.1-0.7c-0.3-1-1.9-2-2.7-2.7c-0.8-0.7-1.9-0.5-2.7-0.2
		c-0.7,0.4-1.4-0.5-1.6-0.8c-0.2-0.3,1.2-1.6,1.3-2.3c0.1-0.7-1.8-0.7-2.9-1.1c-1.1-0.5,0.1-2.9-0.7-4.1c-0.7-1.1-1.3,0.8-2.3,1.4
		c-1,0.6-1.8,0.4-2.5,0.8c-0.7,0.4-2.1,0.3-3.5,0.1c-1.4-0.1-2.7,0.5-3.1,0.7c-0.5,0.1-2.4-0.5-3.9-0.5c-1.5,0-0.5,0.9-0.1,1.4
		c0.4,0.6,1.3,0.2,2.2,0.2c1-0.1,1.6,0.8,2.2,1.7c0.6,0.9,1.3,1,2.4,2.2c1.2,1.2,1.4,3.5,1.5,4.5c0.2,1,0.4,2.1,0.9,2.7
		c0.5,0.6,1.4,2.1,1.8,3.9c0.4,1.7,0.1,3.1-0.2,3.8c-0.2,0.7-0.3,5.4-0.3,6.9c0,1.5-0.4,1.9-0.7,2.5c-0.3,0.5-0.9,2.8-1.3,3.6
		c-0.4,0.8-1.5,2.2-2,3c-0.5,0.8,0.5,0.8,1.3,0.8c0.8,0,0.5,0.4,1,0.9c0.5,0.5,3.2-1.5,4.2-2.3c1-0.8,2.5-0.5,3.8-0.3
		c1.3,0.2,2.6-0.4,3.8-1.1c1.2-0.7,7.2-1.4,8.5-1.4c1.3,0,3.5-1.9,4.8-2.9c1.3-1,5.4-2.8,6-3.3c0.7-0.5,0.5-0.7,0.5-1.1
		c0-0.4,0-1.2,0.7-1.4c0.7-0.2,0.7-1.6,2.7-2c1.9-0.4,5.6,2.4,6.7,3.3c1.1,0.9,1.3,0.7,1.9,1.8c0.5,1.1,1.3,0.5,1.9,0.5
		c0.6,0,1-0.1,1.8,0.4c0.8,0.4,1-0.1,2.2-0.1c1.2,0,1.9-3.5,2.1-4.4c0.3-0.9-1.4-2.1-1.5-2.6c-0.1-0.6,0.8-1.4,1.4-1.6
		c0.5-0.2,0.4-1.6,0.4-2c0-0.4,0.3-0.9,0.8-0.9c0.5-0.1,1-0.7,1.2-0.4c0.2,0.2-0.4,0.9-0.8,1.4c-0.5,0.5,0.4,1.6,1,2
		c0.5,0.4-0.5,0.7-0.7,0.8c-0.3,0.2,0,0.5,0.3,0.9c0.3,0.4,0,0.3,0.1,0.9c0,0.6,0.3,0.3,0.8,0.3c0.5,0.1,0.6,0.6,1,1.4
		c0.3,0.7-1,0.2-1.5,0.5c-0.5,0.3,0.2,2,0.3,3.4c0,1.5-0.4,3-0.5,4c-0.1,0.9,0.5,1.2,0.7,1.9c0.3,0.7,0.7,0.5,1.1,0
		c0.5-0.4,0.5-0.7,1.4-0.3c1,0.5,1.3,1.3,1.4,2.4c0.2,1.1,2.8,1.8,2.8,2.5c0,0.6-3.9,0.1-5.2,0c-1.2-0.1-2.2-1.7-3-2.7
		c-0.8-1-2-1.7-2.6-1.7c-0.5,0-0.6,0.9-0.8,1.7c-0.2,0.8-1.1-0.6-1.2-1c-0.1-0.4,0-0.8,0.4-1c0.4-0.3-0.1-0.6-0.5-0.8
		c-0.4-0.1-0.8,0.4-1.1,0.8c-0.2,0.4-0.5,0-0.5-0.3c0-0.4-0.4-0.3-1-0.3c-0.6,0-1-1-1.5-1.2c-0.4-0.2-0.7,0.4-0.6,1.1
		c0,0.7,0.6,1,1,1.6c0.4,0.6-0.1,0.7-0.6,1.2c-0.5,0.5-0.8-1-1.3-2c-0.4-1-3.2-2.6-4-3.3c-0.8-0.7-0.5-2.3-1.7-3.8
		c-1.1-1.4-2.5-1.1-3.6-1c-1.1,0.1-0.1,1.1,0.3,1.6c0.4,0.4,1.2,1.1,1.4,2.2c0.2,1.1,1.8,2.7,3.8,3.3c2,0.6,2.4,2.5,2.7,3.2
		c0.3,0.7,1.6,2.7,2.2,4.3c0.7,1.6,1.5,4.8,2,6.3c0.5,1.5,0.8,5.4,1.1,7.4c0.3,1.9,0.4,11.8,0.7,13c0.3,1.2-2.1,6.7-2.5,7.4
		c-0.4,0.7-0.5,2.7-0.5,3.8c-0.1,1.1-0.9,1.8-1.4,2.3c-0.5,0.5-1.1,4.1-1.3,6c-0.2,1.9-2,5.6-2.3,7.1c-0.3,1.5-2,3.4-2.3,4
		c-0.4,0.6-0.9,0.8-1.8,0.9c-0.9,0-2,1.2-2.2,2.9c-0.3,1.7,0.8,0.6,1.7,0.5c0.8-0.1,0.8,1,0.8,1.3c0,0.3,0.6,0.6,1.9,1.5
		c1.3,1,2.1,1,3,1c1,0,0.9,1.3,1,1.7c0.1,0.3,0.6,1.4,1.2,2.8c0.6,1.5-0.3,2.5-0.8,3.5c-0.4,1,0.5,6.5,0.9,7.7
		c0.1,0.2,0.1,0.3,0.1,0.5c1,0.5,2,1.1,2.4,1.4c0.9,0.4,2.8-0.5,3.8-0.8c1-0.3,1.3,1.2,1.5,1.6c0.3,0.4,1.3,1.5,1.7,2.2
		c0.4,0.7,2.7,3.6,3.1,4.5c0.4,0.9,1.2,0.1,2,0.1c0.7-0.1,1.6,0.7,2.3,1.1c0.7,0.5,5.3,0,5.9-0.1c0.6-0.1,1.5-2.5,1.9-3.7
		c0.4-1.2,2.1-3.8,2.4-4.7c0.4-0.9-0.3-2.4-0.5-3c-0.2-0.5,0.3-0.8,1.2-1.2c0.9-0.3,0.9-0.5,2-1c1.1-0.6,4.3-0.8,6.7-1.1
		c2.4-0.3,6.3-0.5,8-0.8c1.7-0.3,2,0.4,3.1,1.1c1.1,0.7,2.1,0.4,3.3,0.2c1.2-0.2,3.6-0.3,4.3-0.3c0.7,0,1-0.4,0.9-1
		c-0.1-0.6,0.5-2.4,0.8-3c0.3-0.6-0.2-1.4-0.5-2.3c-0.4-0.9-0.3-2.7-0.3-3.3c0-0.6-0.4-6.9-0.4-8.7c0-1.8-2-3.2-2.8-3.6
		c-0.8-0.4-1.2-1.5-1.4-2.3c-0.3-0.9,0.5-1.7,1.2-2.3c0.8-0.6,0.2-2.9,0.2-3.4c-0.1-0.5,0.4-1.3,0.8-1.9c0.4-0.6,2-3.7,3.2-5.4
		c1.2-1.8,0.7-4,0.7-5.5c0-1.5,5.5-3.7,6.4-4.2c0.9-0.5,8.8-4.3,10-5c1.2-0.7,1.3-1,1.1-2.4c-0.2-1.5-1.2-8.9-1.3-10.3
		c-8.1-2-15.5-14-15.5-14c1.2-9.3,0.4-15.5-0.5-19.1l0,0l0,0"><title>Setúbal</title></path>
		{CircledNumber}
		</g>
            )
    }
}