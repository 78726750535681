import React from "react"

import LungsIcon from '../images/icons/lungs'
import HeartIcon from '../images/icons/heart'
import styled from "styled-components"

import { EventEmitter } from '../events/event-emitter'

import slugify from '../utils/slugify'

import { DataTable } from './data-table'

const ContentContainer = styled.div` 
`

const TitleContainer = styled.div`
    height: 30px;
    vertical-align: middle;
    display: inline-block;
    line-height: 10pt;
    position: relative;
    margin: 15px 0;
`

const MainTitle = styled.h3`
    font-family: Ubuntu, sans-serif;
    margin: 0;

    span {
        font-size: 25pt;
        background-color: #C3DBDE;
        position: relative;
        padding-left: 40px;
        margin-right: 20px;
        color: #3E363F;

        ::after {
            content: '';
            width: 0;
            position: absolute;
            height: 0;
            border-top: 37px solid #C3DBDE;
            z-index: -1;
            border-right: 20px solid transparent;
        }
    }
`

const Title = styled.h2`
&& {
    display: inline;
    padding-left: 10px;
    font-family: Ubuntu, sans-serif;
    font-weight: 200;
    font-size: 13pt;
    position: absolute;
    top: -7px;
    width: 300px;
}
`

const RegionTitle = styled.p`
    font-weight: normal;
    font-size: 19pt;
    text-transform: capitalize;
    display: inline;
`

export class ContentCards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            selectedDistrict: ''
        }
        this.eventEmitter = EventEmitter;
        this.eventEmitter.subscribe('clickedDistrict', (event) => this.clickedDistrict(event));
        this.peopleByDistrict = [];
    }

    clickedDistrict = (districtName) => {
        this.setState({
            data: this.state.data,
            selectedDistrict: districtName
        });
    };

    filterPeople = (condition) => {
        return this.state.data.filter(person => 
            (slugify(person.district.toLowerCase()).includes(this.state.selectedDistrict))
            && person.conditions.toLowerCase().includes(condition))
    }

    render() {
    return (
    <ContentContainer>
        <MainTitle><span>Fisioterapeutas</span> {this.state.selectedDistrict ? <RegionTitle>{this.state.selectedDistrict}</RegionTitle> : ''}</MainTitle>
        <TitleContainer>
            <LungsIcon />
            <Title>Área Respiratória</Title>
        </TitleContainer>
        <DataTable data={this.filterPeople('breathing')} />
        <TitleContainer>
            <HeartIcon />
            <Title>Área Cardíaca</Title>
        </TitleContainer>
        <DataTable data={this.filterPeople('heart')} />
    </ContentContainer>
    )}
}