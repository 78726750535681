import React from 'react'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import styled from "styled-components";

import AdditionalData from './additional-data'

const StyledTable = styled(Table)`
&& {
    color: #3E363F;
}
`

const CollapsableTableCell = styled(TableCell)`
&& { border: 0; padding: 0; margin: 0; }
`

const StyledNameCell = styled(TableCell)`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: hidden;
    max-width: 300px;
`

const StyledTableCell = styled(TableCell)`
&& {
    font-family: Ubuntu, sans-serif;
    padding: 7px 0;
    overflow: hidden;
    text-overflow: ellipsis;
}
`

const IconTableCell = styled(TableCell)`
&& {
    width: 30px;
    padding: 0;
}
`

const StyledTableHeaderCell = styled(TableCell)`
&& {
    background-color: #FFF;
    color: #3E363F;
    font-family: Ubuntu, sans-serif;
    font-weight: bold;
    padding: 5px 0;
}
`

const StyledArrowHeaderCell = styled(StyledTableHeaderCell)`
&& {
    width: 40px;
}
`

const StyledNameHeaderCell = styled(StyledTableHeaderCell)`
&& {
    width: 230px;
}
`

const StyledPhoneHeaderCell = styled(StyledTableHeaderCell)`
&& {
    width: 125px;
}
`

const StyledEmailHeaderCell = styled(StyledTableHeaderCell)`
&& {
    width: 355px;
}
`

export class DataTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            page: 0,
            rowsPerPage: 4
        }
        this.open = '';
    }


    static getDerivedStateFromProps(props, state) {
        if (props.data !== state.data) {
            return {
                data: props.data,
                open: '',
                page: 0,
            }
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
    }

    setOpen = (name) => { 
        this.setState({
            open: (this.state.open === name ? '' : name)
        }); 
    }

    render() {
        return (
            <React.Fragment>
                <TableContainer component={Paper}>
                    <StyledTable size="small" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledArrowHeaderCell />
                                <StyledNameHeaderCell align="left">Nome</StyledNameHeaderCell>
                                <StyledPhoneHeaderCell align="left">Telefone</StyledPhoneHeaderCell>
                                <StyledEmailHeaderCell>e-mail</StyledEmailHeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(this.state.rowsPerPage > 0 
                            ? this.state.data.sort(function (a, b) {
                                if (a.name < b.name) { return -1 };
                                if (a.name > b.name) { return 1 };
                                return 0;
                            }).slice(
                                this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage) 
                            : this.state.data).map((row) => (
                                <React.Fragment>
                                    <TableRow key={row.name}>
                                        <IconTableCell>
                                            <IconButton aria-label="expand row" size="small" onClick={() => this.setOpen(row.name)}>
                                                {(this.state.open === row.name) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                            </IconButton>
                                        </IconTableCell>
                                        <StyledNameCell component="th" scope="row">{row.name}</StyledNameCell>
                                        <StyledTableCell align="left">{row.phone}</StyledTableCell>
                                        <StyledTableCell>{row.email}</StyledTableCell>
                                    </TableRow>
                                    <TableRow>
                                       <CollapsableTableCell colSpan={4}>
                                        <Collapse in={row.name === this.state.open} timeout="auto" unmountOnExit>
                                                <AdditionalData personDetails={row} />
                                        </Collapse>
                                        </CollapsableTableCell> 
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </StyledTable>
                </TableContainer>
                <TablePagination 
                component="div"
                rowsPerPageOptions={[4]}
                count={this.state.data.length}
                rowsPerPage={4}
                page={this.state.page}
                onChangePage={this.handleChangePage}/>
            </React.Fragment>
        )}
}