import React from 'react';
import { District } from '../district';

export class Aveiro extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Aveiro', props.data)
		}
	}
	
    render () {
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="120" cy="200" r="15" fill="#187795" />
				<text x="120" y="200"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
		
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`}  onClick={ () => this.clicked('aveiro') } name="aveiro" d="M155.5,167.2c-0.6,0.6-0.1,1.4-0.1,1.4s1.9,5.8,2.3,6.7c0.4,0.9,1.2,1.3,1.8,1.5c0.6,0.2,0.3,1-0.1,1.6
		c-0.4,0.6-1,3.2-1,3.4c0,0.2-0.5,2.7-1.9,3.2c-1.5,0.5-4.3-3.6-4.8-4.4c-0.5-0.7-1.9,0.7-2.4,1.1c-0.5,0.3-2.6-1.8-3.4-2.7
		c-0.8-0.8-1.4-0.6-1.4-0.6s-1.1,1-1.9,1.9c-0.9,1,0.9,2.9,1.6,4.2c0.7,1.3-0.8,2-1.7,2.9c-1,0.9-1.8,2-2.2,2.7
		c-0.4,0.7-3.4,5.1-4.2,6.1c-0.8,1-0.5,3.9-0.5,4.6c0.1,0.7,1.6,4.2,1.8,5.1c0.2,0.9,0.7,2.7,0.7,3.1c0,0.4,1.1,0.7,2.1,1.5
		c0.9,0.7,0.4,1.5,0.6,2.6c0.1,1.1-3.5,2.3-4.2,2.6c-0.7,0.3,0,1.1,0.4,1.8c0.3,0.7,0.7,2.3,1,3.8c0.3,1.5,1.7,3.2,2.6,3.7
		c0.9,0.6,0.6,2.1,0.7,2.9c0,0.8-8,2.1-8.6,2.2c-0.7,0.1-0.8,0.8-0.9,1.6c-0.1,0.7,2.4,3.2,2.7,3.8c0.4,0.6-3,4-3.8,4.9
		c-0.8,0.9-0.4,6.3-0.4,6.3c-1.3,0.3-4.2,2.2-4.8,2.4c-0.6,0.2-0.7,6.2-0.8,7.8c-0.1,1.6-2.7,2.7-3.7,2.8c-1,0.1-2.5-0.1-3-0.3
		c-0.6-0.2-2.8-0.1-4.1-0.2c-1.3-0.1-2.2-2.9-2.5-3.4c-0.3-0.5,0.3-0.7,1.1-0.9c0.9-0.1,4-1.3,4.8-1.5c0.8-0.2-0.1-2.6-0.3-3
		c-0.1-0.4-0.4-1.4-0.2-2.3c0.1-0.9-0.6-2.9-2.1-5.7c-1.5-2.9-4.5-0.1-5.8-0.5c-1.3-0.3-1.1-1-1.9-2.3c-0.9-1.3,1.9-5.2,1.9-5.2
		l-3.2-4.3l-6.4,7.8l-3.5-2.4c-0.2-0.2-0.3-0.5-0.5-0.8c-0.1-0.1-0.2-0.3-0.4-0.5c-0.1-0.1-0.2-0.2-0.3-0.4
		c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.1,0-0.2,0-0.4c0-0.1-0.1-0.3-0.1-0.5c0-0.1,0-0.2-0.1-0.4
		c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.2-0.1-0.4-0.1-0.6c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.4-0.4-0.6-0.7-0.9c-0.1-0.1-0.4-0.2-0.5-0.4
		c-0.2-0.1-0.3-0.3-0.4-0.4c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.2,0-0.4-0.1-0.6-0.3c-0.1-0.2-0.3-0.4-0.5-0.5
		c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.6,0.2-1.1,0.5-1.5c0.8-0.9,1.3-2.4,1.6-3
		c0.3-0.6,1.5-2.4,1.4-3.4c-0.1-1.1-0.4-4.2,0.9-5c1.3-0.8,1.8-1.3,2.2-0.8c0.4,0.5,0.6,1.1,1.2,0.3c0.5-0.8,0.9-1,1.6-1.5
		c0.7-0.5,2.3-0.7,2.4-1.8c0-1-0.1-1.7,0.7-2.2c0.8-0.6,1.5-0.3,2.4-0.1c0.9,0.2,1.5,1.7,2,0.9c0.5-0.7-1.1-2.2-2.1-2.5
		c-1.1-0.3-5-1.7-4.7-2.7c0.2-1.1,1.2-1.8,1.9-1.1c0.7,0.7,0.9,0.4,1.2,0.1c0.2-0.2,1.5-0.4,2.2-0.5c0.7-0.1,0.9,0.5,1-0.3
		c0.1-0.8,0.2-1.2,0.5-1.4c0.3-0.2,0.7-1,0.1-1.2c-0.6-0.2-1.2,0.3-1.5,0.7c-0.3,0.4-0.5,0.5-1,0.3c-0.5-0.2-0.1-1.9-0.6-1.3
		c-0.6,0.6-0.8,1.1-1.5,1.1c-0.7,0-1.3,0.3-1.5-0.2c-0.2-0.5-0.2-1.3-0.6-0.9c-0.4,0.4-1.1,1.4-1.4,0.5c-0.4-0.9-0.4-1.7-0.2-2.5
		c0.2-0.7,0.3-0.9,0.1-1.4c-0.2-0.6-0.3-1,0.1-1.8c0.5-0.8,1.5-1.5,2.4-2c0.9-0.5,2.6-1,3.2-1.9c0.5-0.9,0.8-1,0.4-1.3
		c-0.4-0.3-0.5-0.4-0.8,0.2c-0.3,0.6-0.4,0.5-0.5,0c-0.1-0.5-0.7-2.4-0.8-1.2c-0.1,1.1-0.1,1.9-0.7,2.4c-0.6,0.5-1.8,1.3-1.9,0.2
		c-0.1-1.1-0.1-3.6,0.4-4.5c0.5-0.9,1.4-2.1,1.1-2.5c-0.3-0.3-1.2-1.8-1.6-0.5c-0.5,1.2-2,5.3-1.4,6.5c0.6,1.2,1.1,2.2,0.2,3.5
		c-0.8,1.4-1.4,2.5-1.9,3.1c-0.5,0.6-0.8,1-0.6,2.1c0.2,1.1,0.3,4.6-0.5,5.6c-0.8,0.9-2.4,2.7-2.3,4.2c0.1,1.6,0.2,2.8,0.4,2.8
		c0.1,0,0.4,0.9-0.2,1.4c-0.6,0.5-1,0.8-1.5,0.5c-0.4-0.4-0.7,0-0.7-0.7c0-0.7,0.4-4.6,0.7-5.8c0.4-1.2,1.3-3.8,1.4-5.1
		c0-1.3,0.5-3.8,1.2-4.8c0.7-1.1,1.8-3.3,1.9-4.7c0.1-1.5,0.6-6.2,1-7.4c0.4-1.2,1.1-3.3,1.4-4.8c0.2-1.5,1.7-8.3,1.7-9.7
		c0.1-1.3,1.1-10.1,1.1-11.5v-0.7c0.2,0.1,0.5,0.1,0.7,0.2c0.4,0,0.8,0.1,1.1,0c0.2-0.1,0.2-0.4,0.4-0.6c0.1,0,0.1,0,0.2,0
		c0.2,0.1,0.4,0.1,0.6,0.3c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.1,0.4,0.2,0.7,0.4c0.5,0.2,1.3,0.4,1.8,0.1c0.3-0.1,0.5,0,0.8,0.1
		c0.2,0.1,0.5,0,0.7,0c0.2-0.1,0.5-0.1,0.8-0.3c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1,0-0.2,0.1-0.3c0.1-0.2,0.4-0.3,0.7-0.3
		c0.3,0,0.5-0.1,0.5-0.5c0-0.1,0-0.3,0.1-0.4c0-0.1,0.1-0.2,0.2-0.4c0.1-0.3,0.1-0.5,0.4-0.7c0.2-0.1,0.5-0.2,0.7-0.1
		c0.3,0.1,0.6,0.2,0.9,0.1c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.1-0.3,0.3-0.4c0.2-0.2,0.7-0.2,0.8,0.2c0.1,0.2,0.2,0.5,0.1,0.8
		c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.2,0.1,0.6,0.3,0.7c0.2,0.1,0.6,0.2,0.9,0.1c0.2-0.1,0.3-0.2,0.5,0c0.2,0.1,0.4,0.4,0.6,0.6
		c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0,0.5-0.1c0.2-0.1,0.3-0.1,0.5,0c0.2,0.1,0.3,0.1,0.4,0c0.2-0.2,0.3-0.3,0.5-0.3
		c0.2,0,0.5,0,0.6-0.2c0.1-0.2,0.1-0.4,0.1-0.6c0-0.2,0-0.5,0-0.7c0-0.2,0-0.4-0.1-0.6c-0.1-0.2-0.2-0.4-0.2-0.6
		c0-0.3,0.2-0.4,0.4-0.5c0.2,0,0.4,0,0.6-0.1c0.1-0.1,0.2-0.3,0.4-0.4c0.5-0.2,0.8,0.4,1.1,0.7c0.4,0.4,1,0.4,1.3,0.7
		c0.2,0.2,0.4,0.4,0.5,0.6c0.2,0.2,0.4,0.4,0.4,0.6c0,0.2-0.1,0.3-0.3,0.4c-0.2,0.1-0.2,0.3-0.1,0.5c0.3,0.5,0.8,0.6,1.3,0.8
		c0.6,0.2,1.1,0.5,1.6,1c0.4,0.4,1.1,0.9,1.7,0.7c0.2-0.1,0.4-0.2,0.7-0.2c0.2,0,0.4,0.1,0.6,0c0.5-0.2,0-1.2-0.1-1.5
		c-0.2-0.6-0.5-1.3-0.8-1.9c-0.1-0.2-0.2-0.5-0.4-0.7c-0.1-0.2,0-0.4,0-0.6c0.1-0.2,0.2-0.5,0.4-0.7c0.1-0.2,0.4-0.2,0.6-0.2
		c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0,0.5,0c0.2,0,0.4,0,0.5,0c0.2,0,0.5,0.1,0.7,0
		c0.5-0.1,1-0.2,1.5-0.3c0.5-0.1,0.9-0.2,1.3-0.4c0.2-0.1,0.4-0.1,0.5-0.2c0.2-0.1,0.4-0.2,0.6-0.4c0.4-0.2,0.7-0.4,1.1-0.7
		c0.4-0.3,0.6-0.6,1.1-0.8c0.2-0.1,0.4-0.2,0.6-0.2c0.2-0.1,0.5-0.1,0.7-0.1c0.2,0,0.5,0,0.7,0c0.2,0.1,0.4,0.1,0.6,0.2
		c0.2,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.3,0.4,0.5,0.4c0,0,0,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.2,0.1,0.4,0.2
		c0.2,0,0.4,0.1,0.6,0.1c0.4,0,0.9-0.2,1.3-0.4c0.2-0.1,0.5-0.2,0.7-0.4l0.3-0.3c0.5,1.2-1.5,4.3-1.8,4.8c-0.2,0.5-0.2,0.7,0.1,1.2
		c0.4,0.5,0.6,1.3,1,2.1c0.4,0.8,1.8,1.1,2.4,1.3c0.6,0.2,3,1.2,4.1,1.8c1.1,0.6,1.9,0,1.9,0l4.6-3.1c0,0,2.5,3.9,3,4.6
		c0.4,0.7-1.4,1.2-2.6,1.7C157.8,164.6,156.1,166.6,155.5,167.2z"><title>Aveiro</title></path>
		{CircledNumber}
		</g>
            )
    }
}