import React from 'react';
import { District } from '../district';

export class Viseu extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Viseu', props.data)
		}
	}

    render () {
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="180" cy="200" r="15" fill="#187795" />
				<text x="180" y="200"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('viseu') } name="viseu" d="M244.8,142.3c0,1.1-1.4,4.1-1.7,4.9c-0.2,0.7-1.3,3.4-1.7,5.7c-0.4,2.3-4,7.1-4.8,8.2c-0.8,1.1,1,2.9,1.6,3.6
	c0.6,0.7,0.7,1.6,0.9,3.8c0.1,2.2-4.5,4.7-5.3,5.3c-0.8,0.6-1.4,0.7-2.9,0.7c-1.4,0-2.4,1-3.4,1.9c-1,0.9,1.5,2.9,2.4,3.9
	c1,1,0.3,1.8,0.3,1.8l-4.2,7l-9.7-10.9c0,0-6.4,11.1-6.8,11.9c-0.4,0.7,0.1,1.1,0.1,1.1s3.8,3,4.7,3.5c0.9,0.5,0.5,1.4,0.5,1.4
	s-1.5,6.6-1.5,7.1c0,0.5,3.9,1.2,4.9,1.2c1.1,0.1,0.7,1.8,0.6,2.8c-0.2,1-0.7,4.3-0.7,5.9c0,1.6-1,3-1.4,3.5c-0.4,0.6,0.1,1-0.7,1.8
	c-0.7,0.9-0.7,2.4-0.7,3.9c-0.1,1.5-5.3,2.9-7.7,3.3c-2.3,0.4-4.1,1.9-5.7,3.2c-1.6,1.3-8.6,1.6-10.5,1.7c-1.7,0.1-2.4,0.9-3.5,2
	c-0.1,0.1-0.2,0.3-0.4,0.4c-1.3,1.3-4.5,4.7-5.4,5.6c-1,0.9-4.9,3-6.3,3.7c-1.4,0.6-4,2.3-4.4,2.8c-0.4,0.5-2.8,2.5-4.4,3.9
	c-1.6,1.4-4.4,4.1-5.7,5.3c-1.3,1.2-4.7,0.3-5.8-0.1c-1.1-0.4-2.6-0.4-4.1-0.4c-1.5,0-5,1.7-5.7,2.2c-0.7,0.4-1.3-0.2-1.6-0.7
	c-0.3-0.5-2.2-1-3.2-1.6c-1-0.6-3.4-1.7-4.2-2.2c-0.7-0.5-2.2-3-2.7-3.9c-0.5-0.9-2.1-0.8-3.3-0.7c-0.1,0-0.2,0-0.3,0.1
	c0,0-0.5-5.4,0.4-6.3c0.8-0.9,4.2-4.3,3.8-4.9c-0.4-0.6-2.8-3.1-2.7-3.8c0.1-0.8,0.2-1.4,0.9-1.6c0.7-0.1,8.6-1.3,8.6-2.2
	c-0.1-0.9,0.2-2.3-0.7-2.9c-0.9-0.6-2.3-2.2-2.6-3.7c-0.3-1.5-0.7-3-1-3.8c-0.3-0.7-1-1.5-0.4-1.8c0.7-0.3,4.4-1.5,4.2-2.6
	c-0.1-1.1,0.4-1.9-0.6-2.6c-1-0.7-2.1-1-2.1-1.5c0-0.4-0.5-2.3-0.7-3.1c-0.2-0.9-1.8-4.4-1.8-5.1c0-0.8-0.3-3.6,0.5-4.6
	c0.8-1,3.8-5.4,4.2-6.1c0.4-0.7,1.2-1.8,2.2-2.7c1-0.9,2.5-1.6,1.7-2.9c-0.7-1.3-2.5-3.2-1.6-4.2c0.8-0.9,1.9-1.9,1.9-1.9
	s0.6-0.2,1.4,0.6c0.7,0.9,2.9,3,3.4,2.7c0.5-0.4,1.8-1.8,2.4-1.1c0.5,0.7,3.4,4.8,4.8,4.4c1.5-0.5,1.9-3,1.9-3.2
	c0-0.1,0.6-2.8,1-3.4c0.4-0.6,0.7-1.5,0.1-1.6c-0.6-0.2-1.4-0.6-1.8-1.5c-0.4-0.9-2.3-6.7-2.3-6.7s-0.5-0.7,0.1-1.4
	c0.6-0.6,2.3-2.6,3.5-3.1c1.2-0.5,3-1,2.6-1.7c-0.4-0.7-3-4.6-3-4.6l-4.6,3.1c0,0-0.8,0.7-1.9,0c-1.1-0.6-3.6-1.6-4.1-1.8
	c-0.6-0.1-2-0.5-2.4-1.3c-0.5-0.8-0.7-1.6-1-2.1c-0.3-0.5-0.4-0.7-0.1-1.2c0.2-0.5,2.2-3.6,1.8-4.8c0.1,0,0.1-0.1,0.2-0.2
	c0.2-0.3,0.5-0.6,0.8-0.9c0.1-0.1,0.2-0.3,0.4-0.5c0.2-0.1,0.4-0.3,0.6-0.5c0.4-0.4,0.7-0.7,1.1-1c0.2-0.1,0.3-0.2,0.5-0.3
	c0.2-0.1,0.4-0.1,0.6-0.2c0.5,0,1.1,0.1,1.6,0.2c0.3,0.1,0.6,0.2,0.8,0.3c0.2,0.1,0.5,0,0.8,0.1c0.1,0,0.2,0.1,0.3,0.2
	c0.1,0.1,0.4,0.1,0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0,0.5,0.1,0.7,0.1c0.2,0,0.4,0,0.6-0.1c0.1-0.1,0.2-0.2,0.3-0.2
	c0.1-0.1,0.2-0.1,0.4-0.2c0.2-0.1,0.4-0.3,0.6-0.4l0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.4-0.5,0.6-0.7
	c0.1-0.1,0.3-0.3,0.5-0.4c0.1-0.1,0.4-0.1,0.5-0.2c0.3,0,0.6,0,0.8,0c0.4,0,0.9,0,1.3,0.3c0.2,0.1,0.3,0.3,0.5,0.4
	c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0.1,0.6,0.3,0.8,0.4c0.3,0.1,0.6,0.1,0.9,0.1c0.3,0,0.6,0.1,1,0.1c0.3,0,0.6,0,0.9-0.1
	c0.2,0,0.5,0,0.7-0.1l0,0c0,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.2,0.5-0.3c0.4-0.3,0.6-0.6,1-0.8
	c0.4-0.2,0.7-0.4,1-0.6c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.1,0.2-0.3,0.4-0.4c0.2-0.1,0.3-0.3,0.5-0.4c0.1-0.1,0.1-0.1,0.2-0.2
	c0.3-0.1,0.7-0.1,1.1-0.1c0.4,0,0.7,0.1,1.1,0.1c0.3,0,0.6,0,0.9,0c0.4,0,0.8-0.1,1.2-0.1c0.3,0,0.7-0.1,1-0.1c0.3,0,0.5,0,0.8,0.1
	c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.5,0,0.7,0c0.2,0,0.4-0.1,0.5-0.2c0.2-0.2,0.3-0.6,0.5-0.8
	c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.2,0.3-0.3,0.4-0.5
	c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.2,0.5-0.4,0.7-0.6c0.2-0.1,0.5-0.4,0.7-0.4c0.2-0.1,0.5,0,0.7,0c0.6,0.1,1.1,0.4,1.7,0.7
	c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0,0.4,0,0.6,0c0.3,0,0.5,0,0.7-0.1c0.4-0.1,0.7-0.1,1-0.4c0.4-0.2,0.7-0.5,1.1-0.7
	c0.2-0.1,0.4-0.1,0.6-0.2c0.2,0,0.5-0.1,0.7-0.1c0.4-0.1,0.8-0.1,1.2-0.1c0.5,0,0.9-0.1,1.4-0.1c0.4,0,0.9,0.1,1.3,0.4
	c0.1,0.1,0.3,0.2,0.5,0.4c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0.2,0.7,0.5,1,0.7c0.7,0.5,1.6,0.5,2.3,1c0.5,0.4,1.2,0.4,1.8,0.4
	c0.6,0.1,1.3-0.4,1.8-0.7c0.3-0.2,0.5-0.5,0.6-0.7c0.2-0.4,0.5-0.6,0.7-0.8c0.2-0.2,0.5-0.4,0.9-0.5c0.4-0.1,0.8,0,1.2,0
	c0.2,0,0.4,0,0.5,0c0.2,0,0.3,0.1,0.5,0.2c0.3,0.1,0.6,0.4,0.9,0.6c0.3,0.3,0.5,0.4,0.9,0.4c0.4,0,0.7,0,1.1,0c0.4,0,0.7,0,1.1-0.1
	c0.3-0.1,0.6-0.2,0.9-0.5c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4-0.2,0.6-0.3c0.6-0.2,1.2-0.5,1.7-0.9c0.6-0.5,1.1-1,1.7-1.3
	c0.7-0.4,1.3-0.8,1.9-1.3c0.5-0.4,0.9-0.9,1.3-1.3c0.9-1,2.2-1.8,3.5-1.9c0.9,0,1.8-0.1,2.7-0.1c0.4,0,0.9,0,1.3,0.1
	c0.4,0,0.6,0,1,0h1.5c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0,0.4,0.1,0.6,0.2c0.1,0,0.2,0.1,0.3,0.2
	c0.4,0.1,0.7,0.2,1.1,0.3c0.3,0.1,0.6,0.1,0.8,0.2c0.7,0.2,1.3,0.7,1.8,1.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.4
	c0.1,0.1,0.3,0.2,0.5,0.4c0.1,0.1,0.2,0.3,0.2,0.4c0.2,0.5,0.5,1,0.7,1.5c0.2,0.3,0.3,0.6,0.5,0.8c0.1,0.1,0.2,0.3,0.4,0.4
	c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0.1,0.6,0.1,0.9,0.1c0.4,0,0.8,0.1,1.2,0.1c0.5,0,1.1,0,1.6,0
	c0.1,0,0.1,0,0.2,0C244.7,139.1,244.8,141.2,244.8,142.3z">
    <title>Viseu</title>
</path>
{CircledNumber}
</g>
            )
    }
}