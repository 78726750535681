import React from 'react';
import { District } from '../district';

export class Coimbra extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Coimbra', props.data)
		}
	}
	
    render () { 
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="140" cy="275" r="15" fill="#187795" />
				<text x="140" y="275"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('coimbra') } name="coimbra" d="M196,289c-1.3,0.8-2.1,2.2-3.2,3.9c-1.1,1.7-2.4,1.7-3.8,2.1c-1.3,0.5-3.7-0.7-5.1-1.1
			c-1.4-0.4-4.2,1.6-5,2.2c-0.6,0.5,0.6,3.5,1.1,4.6c-0.8,0.1-1.5,0.3-2.1,0.8c-0.2,0.1-0.4,0.2-0.5,0.4c-0.1,0.1-0.2,0.3-0.4,0.4
			c-0.3,0.2-0.6,0.4-1,0.4c-0.3,0.1-0.5,0-0.8,0.1c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0
			c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.7,0.2c-1,0.4-2,0.8-2.9,1.3c-1.4,0.8-2.5,2-4.1,2.4c-0.6,0.2-1.2,0.2-1.8,0.1
			c-0.2-0.1-0.7-0.1-0.8-0.4c-0.1-0.3,0.2-0.6,0.3-0.8c0.2-0.4,0.5-0.8,0.8-1.2c0.7-0.9,1.2-1.9,1.8-2.8c0.3-0.4,0.6-0.8,1.1-1.2
			c0.4-0.3,0.8-0.6,1.1-1c0.2-0.3,0.5-0.7,0.4-1.1c-0.2-0.4-0.7-0.3-1-0.3c-0.9,0.1-1.7,0.4-2.3,1c-0.4,0.3-0.7,0.7-1.1,1
			c-0.3,0.3-0.6,0.7-0.9,1c-0.2,0.3-0.3,0.6-0.4,0.8c-0.3,0.8-1.1,1.3-1.6,1.9c-0.3,0.4-0.5,0.7-1,0.9c-0.5,0.2-1,0-1.5-0.1
			c-0.6-0.2-1.2-0.2-1.9-0.1c-0.3,0-0.6,0.2-0.8,0.5l-1.2-1.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.2-0.4-0.4-0.6
			c-0.3-0.4-0.5-0.8-0.7-1.2c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.2-0.4c-0.1-0.1-0.1-0.2-0.2-0.2c0-0.1-0.1-0.1-0.2-0.2
			c-0.1-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.1-0.1-0.2-0.1-0.4c0-0.1-0.1-0.1-0.1-0.2
			c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.4-0.3-0.7-0.4-1.1c-0.1-0.3-0.2-0.7-0.2-1.1c0-0.6,0.6-1.1,0.9-1.7c0.1-0.2,0.2-0.4,0.1-0.5
			c-0.1-0.2-0.3-0.2-0.4-0.3c-0.5-0.2-1-0.4-1.5-0.6c-0.5-0.3-1-0.6-1.6-0.9c-0.4-0.2-0.8-0.5-1.2-0.5c-0.5,0-0.9,0.4-1.3,0.6
			c-0.5,0.4-1,0.9-1.6,1.2c-0.5,0.4-1.1,0.6-1.7,1c-0.5,0.2-0.9,0.6-1.3,0.9c0,0,0,0-0.1,0.1c-0.1,0.1-0.3,0.3-0.5,0.4
			c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.1-0.6,0.2-0.9,0.3c-0.3,0.1-0.5,0.2-0.6,0.5c-0.1,0.2-0.1,0.6-0.1,0.8
			c0,0.4,0,0.7,0.1,1.1c0.1,0.4,0.2,0.7,0.3,1c0.1,0.5,0.2,1,0.3,1.5c0.1,0.4,0.3,0.7,0.4,1.1c0.1,0.4,0.2,0.7,0.3,1.1
			c0.1,0.4,0.2,0.8,0.1,1.1c-0.1,0.3-0.2,0.4-0.5,0.5c-0.3,0.1-0.4,0.3-0.6,0.5c-0.2,0.2-0.5,0.3-0.7,0.2c-0.3-0.1-0.5-0.4-0.8-0.5
			c-0.3-0.1-0.7-0.2-1.1-0.3c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.6,0-0.9,0.2c-0.2,0.2-0.3,0.5-0.4,0.7c-0.1,0.3-0.2,0.6-0.3,0.9
			c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.2,0.2-0.2,0.4c-0.1,0.4-0.2,0.7-0.4,1.1c-0.1,0.4-0.2,0.7-0.4,1
			c-0.2,0.2-0.5,0.4-0.8,0.4c-0.4,0-0.7,0-1.1,0.1c-0.3,0-0.7,0.1-1,0.1c-0.9-0.1-0.9-1.1-1-1.8c0-0.5,0-1.1-0.2-1.6
			c-0.1-0.4-0.3-0.7-0.5-1c-0.4-0.7-0.7-1.6-1-2.4c-0.2-0.9-0.3-1.8-0.4-2.7c0-0.3-0.1-0.7-0.3-1c-0.2-0.2-0.5-0.3-0.7-0.2
			c-0.7,0-1.2,0.6-1.8,0.8c-0.4,0.1-0.8,0.1-1.2,0.2c-0.2,0.1-0.4,0.1-0.6,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0.1
			c-0.1,0-0.2,0.1-0.3,0.1c-0.2,0.1-0.4,0.1-0.6,0.1c-0.4,0.1-0.8,0.1-1.2,0.2c-0.2,0-0.5,0.2-0.7,0.3c-0.2,0.1-0.5,0.2-0.8,0.3
			c-0.4,0.1-0.7,0.1-1,0.1c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0.1-0.4,0.2c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.4,0.6
			c-0.1,0.2-0.4,0.4-0.5,0.7c-0.2,0.3-0.4,0.7-0.5,1.1c0,0.1,0,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.2-0.2,0.4-0.3,0.6
			c-0.1,0.1-0.2,0.3-0.2,0.5c-0.1,0.2-0.1,0.4-0.2,0.6c0,0.1-0.1,0.4-0.1,0.5c-0.1,0.2-0.3,0.4-0.4,0.6c-0.1,0.2-0.3,0.2-0.5,0.1
			c-0.2-0.1-0.3-0.2-0.5-0.3c-0.2,0-0.4,0-0.6,0c-0.4,0-0.5-0.3-0.6-0.7c-0.1-0.2,0-0.5-0.1-0.7c0-0.4-0.1-0.9-0.2-1.3
			c0-0.1-0.1-0.2-0.1-0.4c0-0.2,0-0.4-0.1-0.6c-0.1-1,0-2,0-2.9c0-0.9-0.1-1.7-0.2-2.6c0-0.2-0.1-0.5-0.1-0.8c0-0.2-0.1-0.4-0.3-0.5
			c-0.2-0.1-0.4-0.1-0.6-0.1c-0.3,0-0.6,0.1-0.8,0.1c-0.6,0-1.1,0.1-1.6,0.4c-0.5,0.4-0.8,1.1-1.2,1.6c-0.2,0.3-0.5,0.5-0.8,0.7
			c-0.3,0.3-0.5,0.6-0.7,1c-0.2,0.4-0.3,0.7-0.5,1.1c-0.2,0.3-0.6,0.5-1,0.6c-0.3,0.1-0.6,0.3-1,0.2c-0.3,0-0.6-0.1-0.8-0.2
			c-0.2-0.1-0.5-0.2-0.6-0.4c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.3-0.1-0.8-0.4-1c-0.3-0.3-0.5-0.6-0.7-1c-0.1-0.1-0.2-0.3-0.3-0.4
			c-0.1-0.1-0.2-0.3-0.4-0.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1-0.3-0.3-0.7-0.5-1c-0.2-0.3-0.4-0.5-0.7-0.7
			c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.1-0.5-0.3-0.7-0.4c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.6-0.1-0.9,0c-0.1,0-0.2,0-0.4,0.1
			c-0.4,0.1-0.9,0-1.3,0c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2,0-0.3,0c-0.2,0-0.5,0-0.7,0c-0.4,0-0.7,0-1.1-0.1h-0.5
			c-0.3,0-0.6-0.1-0.8-0.2c-0.3-0.1-0.7-0.1-1,0c-0.5,0-1.2-0.1-1.6,0.3c-0.4,0.4-1,0.6-1.4,1c-0.2,0.2-0.4,0.3-0.6,0.5
			c-0.2,0.1-0.4,0.4-0.6,0.6c-0.1,0.2-0.3,0.5-0.5,0.6c-0.2,0.1-0.4,0.2-0.7,0.3c-0.5,0.1-1.1,0.2-1.4-0.2c-0.3-0.4-0.6-0.9-0.9-1.2
			c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.5-0.5-0.7-0.7c-0.3-0.3-0.6-0.7-1-1c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.5,0-0.6,0
			c-0.2,0-0.3-0.1-0.5-0.2c-0.3-0.2-0.6-0.4-1-0.7c0.8-2.9,1.5-5.4,1.6-5.6c0.1-0.5,1.3-2.7,2.7-3.3c1.4-0.6,1.8-0.9,2.4-0.3
			c0.6,0.6,1.7,1.3,1.2,0.1c-0.5-1.2-1.1-2.9-2.3-3.1c-1.1-0.2-4-0.6-4.2-1.4c-0.2-0.8,0.4-2-1-2.3c-1.3-0.3-1.7-0.6-1.7-1.1
			c0-0.6-0.6-1.1-0.5-1.7c0.1-0.7,0-1.6,1-2.1c1.1-0.6,1.2-1.2,1.4-1.9c0.2-0.6,3.7-8.2,4-9.1c0.4-1,2.5-6.8,2.7-7.8
			c0.2-1,1.1-4.6,1.4-5.9c0.3-1.4,0.9-4.6,1.1-5.2c0.2-0.6,0.9-4.6,1.1-5.3c0.2-0.7,0.5-3.2,1.6-4.2c1.1-1,1.5-1.3,1.6-2
			c0.1-0.7,0-2.2,0.7-2.6c0.7-0.4,1.2-0.5,1.1-1.3c0-0.5-0.1-1.3,0-2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.1,0.2,0.2
			c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.2,0.4,0.4,0.5,0.5c0.1,0.1,0.4,0.3,0.6,0.3c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.4,0.2
			c0.1,0.1,0.2,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.5,0.4c0.3,0.3,0.5,0.6,0.7,0.9c0.1,0.1,0.1,0.3,0.1,0.4c0,0.2,0.1,0.4,0.1,0.6
			c0.1,0.2,0.1,0.4,0.1,0.6c0.1,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0,0.4c0,0.2,0.2,0.4,0.3,0.6
			c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.2,0.2,0.3,0.4,0.5c0.2,0.2,0.3,0.5,0.5,0.8l3.5,2.4l6.4-7.8l3.2,4.3
			c0,0-2.8,3.9-1.9,5.2c0.8,1.3,0.7,1.9,1.9,2.3c1.3,0.3,4.2-2.4,5.8,0.5c1.5,2.8,2.2,4.8,2.1,5.7c-0.1,0.9,0.1,1.9,0.2,2.3
			c0.1,0.4,1.1,2.8,0.3,3c-0.8,0.2-4,1.4-4.8,1.5c-0.8,0.1-1.5,0.4-1.1,0.9c0.3,0.5,1.1,3.3,2.5,3.4c1.4,0.1,3.6,0.1,4.1,0.2
			c0.6,0.2,2,0.4,3,0.3c1-0.1,3.6-1.2,3.7-2.8c0.1-1.6,0.2-7.5,0.8-7.8c0.6-0.2,3.5-2.1,4.8-2.4c0.1,0,0.2-0.1,0.3-0.1
			c1.2-0.1,2.7-0.2,3.3,0.7c0.5,0.9,1.9,3.5,2.7,3.9c0.8,0.5,3.3,1.6,4.2,2.2c1,0.6,2.9,1.2,3.2,1.6c0.3,0.5,0.9,1.1,1.6,0.7
			c0.7-0.4,4.3-2.1,5.7-2.2c1.5,0,3,0,4.1,0.4c1.1,0.4,4.5,1.3,5.8,0.1c1.3-1.2,4.1-4,5.7-5.3c1.6-1.3,4-3.4,4.4-3.9
			c0.4-0.5,3-2.2,4.4-2.8c1.4-0.6,5.3-2.8,6.3-3.7c1-0.9,4.1-4.3,5.4-5.6c0.1-0.1,0.3-0.2,0.4-0.4c0,0,3.1,2.5,3,4
			c-0.1,1.4-0.4,1.9-1.5,1.9c-1.1,0-3,0.3-3,1.2c0,0.8-0.2,2.8,0.5,3.1c0.7,0.3,4.9,3.2,5,4.2c0.1,1.1,0.7,3.9,0.9,4.8
			c0.2,0.8,1,2.7,0,4.3c-1.1,1.6-4.8,6.9-4.8,7.1c0.1,0.1,1.7,2.8,2.9,3.8c0,0-0.9,2.3-1.2,2.9c-0.2,0.6-0.1,1.7-0.9,2.8
			c-0.8,1.2,0.1,2.1,0.1,2.1s10.6,10.6,11.1,11.1C200.5,286.1,197.3,288.2,196,289z"><title>Coimbra</title></path>
			{CircledNumber}
			</g>
            )
    }
}