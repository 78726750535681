import React from 'react';
import { District } from '../district';

export class Faro extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Faro', props.data)
		}
	}

    render () { 
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="150" cy="695" r="15" fill="#187795" />
				<text x="150" y="695"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('faro') } name="faro" d="M233.7,703.4c-0.6,1.1-0.7,2.4-1.1,1.2c-0.4-1.2-0.9-2-2.5-2.1c-1.6,0-9.9,0.1-11.9,0.9
		c-1.9,0.8-6.4,3.4-7.5,5.2c-1,1.8-2.7,3.8-4.4,4.8c-1.7,1-5.1,4.5-6.1,4.7c-0.9,0.3-4.1,0.5-5.4,1.5c-1.2,1-3.1,3.2-4.5,3.1
		c-1.4-0.1-3.1,0.2-4.7,1.9c-1.6,1.7-4.3,4.2-6.2,4.3c-1.9,0.1-3.5,0.7-3.9,0.4c-0.4-0.2-2.3-0.7-2.7-0.4c-0.3,0.3-0.4,1-1.5,0.1
		c-1-0.9-20.3-12.2-21.1-12.4c-0.8-0.2-1.5,0-2.6-1.1c-1-1.1-1.1-2.1-3.3-1.8c-2.2,0.3-2.3,0.1-3.2,0.8c-0.8,0.7-1.5,0.8-2.6,0.8
		c-1.1-0.1-2.2-1.4-3.1-1.1c-0.9,0.3-2.3,2-2.3,2s-0.5,0.4-1.1,0.3c-0.7-0.1-2.9-1.5-2.9-1.5s-2.4-2.9-3.8-2.9
		c-1.3,0.1-3.3,0.1-4.5,0.8c-1.3,0.7-2.9-0.3-4.6-0.1c-1.7,0.2-3.9-0.2-4.8-0.1c-0.9,0.1-3.1,0-3.6-0.8c-0.5-0.8-1.3-2.4-1.9-2.4
		c-0.7,0-0.9,1.7-1.5,1.7c-0.6-0.1-1.5-1.3-2.2-0.9c-0.7,0.3-2.4,1-3.4,0.9c-1,0-2.8,0.6-3-0.1c-0.2-0.7,0.3-0.9-0.3-1.3
		c-0.6-0.4-1-1-1.3-1c-0.4,0-1.2,0.7-0.8,1.1c0.4,0.4,0.9,0.7,0.5,0.8c-0.5,0.2-1.2,0.7-1.6,0.3c-0.4-0.4-0.5-0.7-0.9-0.6
		c-0.4,0.2-2.4,1.2-1.9,1.9c0.5,0.6,0.5,1.7,0.3,2.3c-0.2,0.6-0.7,1.8-1,0.9c-0.3-0.8-0.4-1.2-1.2-1.2c-0.8,0-6.2-0.3-6.6,0.4
		c-0.3,0.7-1,2-2.4,2c-1.4,0.1-2.1,0.2-2.9-0.4c-0.7-0.6-1.1-1.3-1.9-1.1c-0.8,0.1-1.6,1.3-1.8,1.7c-0.2,0.4-1.2,2.3-2.1,2.2
		c-0.9-0.1-0.7-0.8-1.9-0.7c-1.2,0.1-2.6,0.1-3.1,0.8c-0.5,0.7-1,1.5-1.7,1.5c-0.7,0-2.2-0.3-2.3,0.7c-0.1,1-0.1,2.4-0.7,2.4
		c-0.6,0-1.1,0-1.3,0.6c-0.2,0.5-0.2,1.5-0.8,1.3c-0.6-0.2-1-1.2-0.8-2.2c0.2-1-0.1-1.7-0.7-2.2c-0.6-0.5-2-1.7-2.1-1.2
		c-0.1,0.5-0.1,1.3-0.6,1.2c-0.5-0.1-1.1-0.7-1.1-1.2c0-0.5,0.8-1,1.2-1.3c0.4-0.3,0.8-0.8,1-1.4c0.1-0.6,0.2-1.5,0.7-1.7
		c0.4-0.1,1.3-0.4,1.2-1.3c-0.2-0.9,0.1-2.9,0.5-3.5c0.4-0.5,2.9-3.9,4-4.4c1.2-0.5,1.7-1.6,1.7-2.6c0-1.1,0.6-2.8-0.1-3.2
		c-0.7-0.5-0.9-0.6-0.9-1.1c0-0.4,0.5-1,0-1.2c-0.4-0.2-1.2-0.7,0.4-1.2c1.7-0.5,1.8-0.5,2.6-1.7c0.7-1.2,3.6-5.6,3-7.1
		c-0.5-1.5-1.7-3.4-1.2-3.9c0.5-0.5,0.9-1.1,0.4-1.5c-0.5-0.5-1.1-0.7-0.6-1.3c0.5-0.6,0.7-0.9,0.6-1.9c-0.1-1-0.3-1.9,0.5-2.2
		c0.8-0.4,1.3-1,1.9-2.2c0.7-1.1,4.5-7.1,4.3-8.8c-0.1-1.2-0.2-3-0.1-4.5c1.7,0.2,5.3,0.8,5.7,2.3c0.5,1.9,1.6,5.2,3.2,5.5
		c1.6,0.4,7.8,1.9,9.1,2.1c1.3,0.2,2.3,0.2,3-0.3c0.7-0.4,4.5-3.2,5.9-2.9c1.3,0.3,14.5,3,14.5,3l5.3-5.6c0,0,7.8,0.2,7.9,0.2
		c0.1,0,3.8-2.4,3.9-2.4c0.1,0,4,3.5,3.7,5.2c-0.3,1.8-1,4.1,0.6,4.5c1.6,0.4,13,3.8,14.4,4.2c1.4,0.4,7.8,2.7,8.9,2.3
		c1.1-0.4,10.2-4,8.1-5.1c-2-1-2.9-1.8-1.5-2.9c1.4-1.1,2.1-1.3,3.2-1.3c1.1,0,9.9,0.8,9.2-0.6c-0.7-1.4-0.2-1.6,0-2.5
		c0.3-1,0.4-2.2,1.1-2.2c0.8,0,2.9,0.7,3.3-0.3c0.4-1,1.1-2.3,2.3-2.5c1.1-0.2,9.3-1.7,11.5-3.5c2.3-1.8,3.5-3.4,5.2-2.9
		c1.6,0.6,6.1,2.5,6.9,1.7c0.9-0.8,3.4-3.9,4.7-3.7c1.3,0.2,3.4,0.8,4.4,0.2c0.8-0.5,2.8-1.5,4.5-2c1.5,3.1,3.4,6.4,3.6,7.3
		c0.3,1.2,1.9,7.2,1.8,9.3c-0.2,2.2-0.4,10.7,0.4,12.3c0.8,1.5,3.8,4.2,2.9,6.2c-0.8,1.9-2.1,4.2-1.3,5.6c0.8,1.4,2.2,3.1,1.6,4.3
		c-0.6,1.2-0.8,1.8-0.4,2.2C232,700.2,234.3,702.3,233.7,703.4z"><title>Faro</title></path>
		{CircledNumber}
		</g>

            )
    }
}