import React from 'react';
import { District } from '../district';

export class Braga extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Braga', props.data)
		}
	}

    render () { 
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="140" cy="80" r="15" fill="#187795" />
				<text x="140" y="80"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('braga') } name="braga" d="M192.4,81.4c0,0.5-0.3,1-0.6,1.5c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0-0.1,0.1-0.2,0.1
	c0,0-0.1,0.1-0.1,0.2c-0.4,0.4-0.6,1-1.1,1.3c-0.3,0.2-0.5,0.3-0.8,0.4c-0.2,0.1-0.5,0.4-0.7,0.5c-0.2,0.1-0.5,0.3-0.7,0.5
	c-0.4,0.2-0.7,0.5-1,0.7c-0.1,0.1-0.2,0.2-0.4,0.4c-0.1,0.1-0.2,0.1-0.4,0.2c-0.4,0.2-0.8,0.5-1.2,0.7c-0.4,0.2-0.7,0.5-1.1,0.7
	c-0.1,0.1-0.3,0.2-0.4,0.3c-0.7,0.4-1,1-1.5,1.6c-0.3,0.3-0.5,0.7-0.7,1c-0.3,0.3-0.4,0.6-0.5,1c-0.1,0.4-0.1,0.7-0.2,1
	c0,0.1-0.1,0.3-0.1,0.5c0,0.1-0.1,0.2-0.1,0.4c-0.1,0.3-0.2,0.6-0.3,0.9c0,0.1-0.1,0.3-0.2,0.4c-0.2,0.3-0.5,0.6-0.8,0.9
	c-0.2,0.2-0.4,0.3-0.5,0.5c-0.1,0.3-0.2,0.6-0.5,0.8c-0.2,0.2-0.2,0.5-0.4,0.6c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.2-0.2,0.4-0.3,0.5
	c-0.2,0.3-0.4,0.7-0.6,1.1c-0.2,0.3-0.5,0.6-0.8,0.8c-0.3,0.2-0.6,0.4-0.8,0.7c-0.1,0.2-0.3,0.6-0.3,0.9v1.1c0,0.4,0,0.7,0,1
	c0,0.8,0,1.7-0.2,2.5c-0.2-0.1-0.5,0-0.6,0.3c-0.6,1-1.4,1.7-2.3,2.5c-0.2,0.2-0.5,0.5-0.8,0.6c-0.2,0.2-0.5,0.3-0.7,0.5
	c-0.5,0.5-1,1.1-1.5,1.7c-0.1,0.2-0.3,0.4-0.6,0.4c-0.3,0.1-0.5-0.2-0.7-0.4c-0.4-0.3-0.8-0.5-1.2-0.8c-0.9-0.6-1.5-1.3-2.3-2
	c-0.6-0.5-1.1-1.5-2.1-1.6c-0.9-0.1-1.8,0-2.7-0.1c-0.5-0.1-0.7-0.1-1.1-0.4c-0.3-0.3-0.6-0.5-0.9-0.8c-0.1-0.2-0.3-0.4-0.5-0.6
	c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.5-0.6-0.6c-0.2-0.2-0.5-0.4-0.7-0.6c-0.4-0.4-0.7-0.9-1.1-1.2c-0.4-0.3-0.7-0.6-1-0.8
	c-0.4-0.3-0.7-0.8-1.2-1.1c-0.4-0.3-0.8-0.6-1.3-0.8c-0.2-0.1-0.4,0-0.6,0c-0.2,0-0.5,0-0.7,0c-0.5,0-0.9,0.1-1.4,0.3
	c-0.3,0.2-0.7,0.4-1,0.6c-0.4,0.3-0.7,0.7-1,1.1c-0.3,0.4-0.6,0.8-0.9,1.1c-0.4,0.4-0.8,0.8-1.1,1.2c-0.2,0.3-0.3,0.6-0.6,0.9
	c-0.3,0.3-0.7,0.5-1.2,0.5c-0.6,0.1-1.2,0.4-1.7,0.3c-0.6-0.1-1.1,0.2-1.6,0.3c-0.4,0.1-0.7,0.1-1.1,0.1c-0.3,0-0.6,0.1-1,0.1
	c-0.7,0-1.4-0.2-2.1-0.4c-0.5-0.1-1-0.1-1.5-0.4c-0.6-0.2-1.1-0.5-1.5-0.9c-0.3-0.2-0.6-0.4-0.9-0.6c-0.4-0.2-0.7-0.2-1.2-0.2
	c-0.7,0-1.4,0-2.1,0.1c-0.4,0.1-0.7,0.1-1,0.2c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.4,0-0.5,0.1c-0.4,0.1-0.7,0.3-1.1,0.4
	c-0.3,0.1-0.7,0.1-1.1,0.1h-1.1c-0.4,0-0.7,0.1-1.1,0.1c-0.7,0-1.3-0.2-1.9-0.2c-0.8,0-1.5,0.3-2.3,0.6c-0.2,0.1-0.4,0.2-0.6,0.3
	c-0.1,0-0.3,0.1-0.5,0.1c-0.2,0.1-0.4,0.1-0.6,0.2c-0.3,0.1-0.6,0.1-0.9,0.2c-0.2,0.1-0.3,0.2-0.5,0.3c-0.5,0.2-1.1,0.1-1.7,0.2
	c-0.5,0.1-1.1,0.4-1.6,0.1c-0.6-0.2-0.9-0.8-1.2-1.3c-0.3-0.5-0.8-0.8-1-1.3c-0.2-0.5-0.1-1,0.2-1.3c0.4-0.4,0.6-0.7,0.6-1.3
	c0-0.5,0.1-1.1-0.1-1.6c-0.2-0.5-0.5-1-0.8-1.4c-0.3-0.4-0.6-0.8-1-1c-0.6-0.3-1.1-0.5-1.7-0.8c-0.6-0.2-1.2-0.4-1.7-0.7
	c-0.6-0.3-1.1-0.7-1.6-1c-0.6-0.4-1.3-0.6-2-1c-0.7-0.3-1.4-0.6-2-1c-0.4-0.3-0.8-0.6-1.3-0.7c-0.2-0.2-0.7-0.3-1-0.5
	c-0.3-0.2-0.6-0.3-1-0.5c-0.4-0.2-0.8-0.3-1.1-0.7c-0.5-0.6-0.8-1.2-1.3-1.6c-1.1-1-2.6-1.5-4-1.8c-0.2-0.5-0.4-0.9-0.5-1.2
	c-0.6-1.5-2.2-4-1.3-4.7c0.9-0.7,1.6-1,1.8-0.2c0.1,0.8,0.1,1.8,0.6,1.8c0.5-0.1,0.7-1.8,0-2.4c-0.7-0.7-2.2-1.8-2.2-2.5
	c0.1-0.7-0.2-2.2-0.4-3.5c-0.1-0.8-0.5-2.7-0.7-4c0,0.1,0.2,0.1,0.3,0.1c0.2,0.1,0.5,0.1,0.7,0.2c0.3,0.1,0.7,0.1,1,0.1
	c0.4,0,0.7,0,1.1,0c0.3,0,0.6,0.1,0.8-0.2c0.1-0.1,0.2-0.2,0.2-0.3c0.1-0.2,0.1-0.5,0.2-0.7c0.1-0.5,0.4-0.9,0.8-0.5
	c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.1,0.4,0.4,0.6,0.5c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.6,0,0.9,0
	c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.4-0.2c0.3-0.1,0.4-0.3,0.6-0.6c0.4-0.5,0.7-1.1,1.2-1.5c0.2-0.2,0.4-0.5,0.6-0.6
	c0.2-0.2,0.5-0.3,0.7-0.4c0.2-0.1,0.4-0.2,0.6-0.3c0.2,0,0.5-0.1,0.7-0.2c0.2,0,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.1,0.4-0.1
	c0.3-0.1,0.6-0.1,0.9-0.2c0.3-0.1,0.6-0.2,1-0.3c0.2-0.1,0.5,0,0.7-0.1c0.2,0,0.5-0.1,0.8-0.1c0.5,0,1,0,1.5,0.2
	c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.2,0.7,0.3c0.8,0.3,1.6,0.7,2.2,1.2c0.6,0.5,1.2,1,1.9,1.3c0.6,0.2,1.3,0.5,1.9,0.4
	c0.6,0,0.8-0.3,1.1-0.7c0.2-0.4,0.5-0.7,1-1c0.4-0.2,0.8-0.5,1.2-0.9c0.3-0.3,0.5-0.7,0.5-1.1c-0.1-0.5-0.3-0.9-0.5-1.3
	c-0.2-0.4-0.3-0.9-0.2-1.3c0.1-0.5,0.4-0.9,0.7-1.3c0.2-0.4,0.3-0.7,0.4-1.1c0.1-0.5,0.2-0.9,0.5-1.4c0.2-0.5,0.4-0.9,0.4-1.4
	c0-0.6-0.1-1.2-0.1-1.8c0.1-0.7,0.5-1.1,1.1-1.5c0.7-0.4,1.4-0.7,2.1-1.1c0.6-0.4,1.1-0.7,1.8-0.7c0.7-0.1,1.5,0.1,2.2-0.1
	c0.2,0,0.5-0.1,0.8-0.1c0.2,0,0.4,0,0.6,0c0.5-0.1,1-0.2,1.5-0.4c0.5-0.2,1.1-0.3,1.5-0.6c0.4-0.3,0.7-0.6,1.1-0.9
	c0.4-0.3,0.8-0.2,1.1-0.4c0.4-0.3,0.8-0.6,1.3-0.8c0.5-0.2,0.9-0.2,1.3-0.2c0.4,0,0.8,0,1.1,0.1c0.5,0.1,1,0.3,1.5,0.3
	c0.2,0,0.5,0,0.8,0c0.4,0,0.8,0,1.2,0.1c0.3,0.1,0.5,0.2,0.8,0.2h0.6c0.2,0,0.4,0.2,0.5,0.3c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0.1
	c0.1,0,0.3,0,0.5,0.1c0.4,0,0.7-0.2,1.1-0.5c0.4-0.2,0.6-0.5,1-0.7c0.4-0.3,0.7-0.8,1.1-1.1c0.5-0.4,1-0.8,1.6-1.1
	c0.3-0.2,0.5-0.3,0.9-0.4c0.4-0.2,0.8-0.3,1.2-0.5c0.2-0.2,0.5-0.3,0.7-0.5c0.2-0.1,0.5-0.2,0.7-0.4c0.2-0.1,0.4-0.3,0.7-0.4
	c0.3-0.1,0.6-0.2,1-0.3c0.6-0.1,1.2-0.2,1.7-0.5c0.2-0.1,0.3-0.2,0.5-0.4c0.1-0.1,0.4-0.3,0.5-0.3l1.6-1.1l4.2,2l6.5-1
	c0,0,0,0,0.1-0.1c0,0,0.7,0.7,2,1.5c1.2,0.8,0.6,3,0.6,3c0,0.5,0,1,0,1.5c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.3,0.5
	c-0.2,0.2-0.3,0.3-0.5,0.5c-0.2,0.1-0.3,0.3-0.5,0.5c-0.2,0.1-0.4,0.3-0.5,0.5c-0.2,0.4-0.5,0.8-0.8,1.2c-0.6,0.8-1.2,1.7-1.8,2.5
	c-0.4,0.6-0.9,1.1-1.4,1.6c-0.4,0.4-0.7,0.8-1,1.4c-0.1,0.3-0.3,0.9-0.1,1.2c0.1,0.3,0.3,0.6,0.5,0.9c0.2,0.4,0.6,0.5,1,0.7
	c0.6,0.1,1.2,0,1.8-0.1c0.3,0,0.7-0.1,1-0.3c0.3-0.2,0.6-0.4,1-0.6c0.3-0.2,0.6-0.3,1-0.4c0.3-0.1,0.5-0.2,0.8-0.1
	c0.1,0,0.3,0,0.5,0c0.2,0,0.4,0,0.5,0.1c0.2,0,0.4,0.1,0.5,0.2c0.2,0,0.3,0.1,0.5,0.1c0.4,0.1,0.7,0.3,1,0.5c0.1,0,0.1,0.1,0.2,0.2
	c0.1,0,0.1,0.1,0.2,0.1c0.2,0.2,0.4,0.6,0.3,1c-0.1,0.5,0,1,0.1,1.4c0,0.5,0.1,1,0.2,1.4c0,0.2,0,0.5,0,0.8c0,0.3,0,0.6,0,0.9
	c0,0.6,0.1,1.1,0.1,1.7c0,0.2,0.1,0.5,0.2,0.7c0.2,0.5,0.3,1,0.4,1.5c0.1,0.4,0.1,0.7,0.6,0.9c0.4,0.1,0.7,0.1,1,0.2
	c0.4,0.1,0.8,0.2,1.3,0.3c0.2,0,0.4,0.1,0.5,0.1c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.4,0.3,0.5,0.3
	c0,0,0,0,0-0.1c0.2,0.1,0.3,0.3,0.5,0.4c0.3,0.1,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.1,0.8,0.2c0.2,0.1,0.4,0.2,0.7,0.3
	c0.2,0.1,0.4,0.2,0.6,0.3c0.5,0.3,1.1,0.6,1.7,0.6c0.2,0,0.4,0.1,0.6,0c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.4,0.5-0.5
	c0.4-0.3,0.9-0.6,1.3-1c0.5-0.5,0.9-1,1.3-1.5c0.4-0.4,0.8-1,1.5-0.9c0.5,0,1,0.5,1.4,0.8c0.5,0.5,1.1,0.9,1.6,1.5
	c0.5,0.6,0.7,1.3,0.9,2c0.2,0.7,0.5,1.5,0.8,2.2C192,80,192.4,80.7,192.4,81.4z">
        <title>Braga</title>
    </path>
	{CircledNumber}
	</g>
            )
    }
}