import React from 'react';
import { District } from '../district';

export class CasteloBranco extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Castelo Branco', props.data)
		}
	}
    render () { 
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="235" cy="310" r="15" fill="#187795" />
				<text x="235" y="310"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('castelo branco') } name="castelobranco" d="M291,303c0.8-1.4-0.2-2.2-0.2-2.2s-4.5-5.2-5.3-6.3c-0.8-1.1-0.8-2.3-1.1-3.3c-0.2-0.9-7.3-4-7.3-4
	s-0.1-7.1-0.1-10.5c0-3.4,5.8-7.7,7.5-9c0.2-0.2,0.6-0.4,1-0.5c-1.7-1.6-4.7-4.5-5.2-5.1c-0.7-0.9-1.5-0.7-1.5-0.7s-5-0.3-6.8-0.2
	c-1.7,0.1-2.5-0.8-3.2-1.3c-0.6-0.5-2.8-1.8-3.4-1.9c-0.6-0.2-1.9,1.2-2.3,1.5c-0.5,0.2-0.8,1.3-1.1,1.9c-0.2,0.7-0.8,0.6-1.5,0.8
	c-0.6,0.2-4.1,0.6-5.2,0.5c-1.1-0.1-5.3,0.8-6,0.7c-0.7-0.1-6.6-1-7.3-0.9c-0.7,0.1,0.9-2.4,1.7-3.4c0.7-1,2.1-3.8,2.8-5
	c0.7-1.2,0.8-7.8,0.9-8.6c0.1-0.8-1.3-1.7-2.3-2.5c-1-0.8-3.4-0.8-4.1-0.8c-0.6,0-1.6,2.1-1.2,2.7c0.4,0.6,0,0.9-0.7,1.1
	c-0.8,0.2-2.6-1.2-3.2-1.6c-0.6-0.5-3.2-0.2-4-0.5c-0.8-0.2-3.3,0-3.9,0c-0.6,0-1,0.2-1.1,0.4c-0.1,0.1-0.6,0.1-0.7,0
	c-0.1-0.2-0.7-0.3-1.5-0.1c-0.7,0.2-2.2,5.4-2.5,6.1c-0.3,0.7-1.6,3.9-1.8,4.5c-0.1,0.7-1.1,0.6-1.3,0.7c-0.3,0.1-0.5,0.2-0.8,0.3
	c-0.3,0.1-0.8,0.3-1.4,0.4c-0.6,0.1-1.6,0.6-2.2,0.7c-0.6,0.1-2.3-0.3-3.1,0c-0.8,0.4-1.6-0.2-2.5-0.2c-1,0-2,1.5-2.5,1.9
	c-0.5,0.5-2.7,3-3.7,3.7c-1,0.6-3.9,3.9-4.2,4.4c-0.4,0.5-1,0.7-1.6,0.5c-0.7-0.2-4.2-0.2-5.5-0.2c-0.2,0-0.5-0.1-0.8-0.4
	c0,0-0.9,2.3-1.2,2.9c-0.2,0.6-0.1,1.7-0.9,2.8c-0.8,1.2,0.1,2.1,0.1,2.1s10.6,10.6,11.1,11.1c0.5,0.5-2.6,2.5-3.9,3.4
	c-1.3,0.9-2.1,2.2-3.2,3.9c-1.1,1.7-2.4,1.7-3.8,2.1c-1.3,0.5-3.7-0.7-5.1-1.1c-1.4-0.4-4.2,1.6-5,2.2c-0.6,0.5,0.6,3.5,1.1,4.6
	c0.1,0,0.2,0,0.4,0c0.7,0,1.5,0,2.3,0c0.4,0,0.8,0,1.2,0c0.2,0,0.6,0.1,0.7,0.4c0.1,0.6-1.5,0.7-1.8,0.8c-0.8,0.1-1.5,0.2-2.2,0.5
	c-0.8,0.3-1.7,0.6-2.5,1.1c-0.2,0.1-0.5,0.4-0.8,0.6c-0.2,0.2-0.4,0.4-0.7,0.6c-0.5,0.5-1.1,0.8-1.7,1.2c-0.3,0.2-0.6,0.5-0.9,0.7
	c-0.4,0.4-1,0.7-1.6,0.8c-1.1,0.3-2.3,0.5-3.2,1.3c-0.4,0.3-0.8,0.5-1.3,0.7c-0.2,0.1-0.5,0.1-0.7,0.2c-0.1,0-0.3,0-0.5,0.1
	c-0.3,0-0.5,0.2-0.7,0.3c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.3,0.1-0.5,0.1c-0.3,0.1-0.6,0.1-0.9,0.2
	c-0.5,0.2-0.8,0.6-1.3,0.9c-0.2,0.2-0.5,0.4-0.7,0.5c-0.2,0.1-0.5,0.4-0.8,0.5c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.4,0.4-0.6,0.6
	c-0.4,0.4-0.7,0.9-1,1.3c-0.2,0.5-0.6,0.9-0.9,1.3c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.2-0.3,0.5-0.5,0.8c-0.2,0.4-0.6,0.8-1,1.1
	c-0.2,0.2-0.5,0.4-0.7,0.6c-0.2,0.2-0.4,0.4-0.6,0.6c-0.2,0.2-0.4,0.5-0.6,0.8c-0.2,0.2-0.4,0.5-0.6,0.8c-0.1,0.1-0.1,0.2-0.2,0.4
	c-0.1,0.1-0.3,0.2-0.4,0.3c-0.5,0.5-1.1,1-1.6,1.5c-0.3,0.2-0.6,0.5-0.8,0.8c-0.4,0.4-0.7,0.7-1.1,1c-0.6,0.2-1.1,0.4-1.7,0.6
	c-0.4,0.1-0.9,0.2-1.3,0.2c-0.4,0-0.8,0-1.2-0.1c-0.4-0.1-0.8-0.2-1.2-0.2c-0.9,0-1.4,0.8-1.6,1.6c-0.2,0.6-0.3,1.1-0.5,1.7
	c-0.1,0.5-0.3,1.1-0.3,1.6c0,0.5,0.1,1,0.1,1.4c0,0.4,0,0.8,0.2,1.1c0.2,0.4,0.6,0.7,1,1c0.5,0.4,1,0.6,1.5,0.7c0.5,0.1,1,0,1.4,0.2
	c0.5,0.2,0.7,0.7,0.8,1.2c0.2,0.6,0.2,1.2,0.2,1.9c0,0.5-0.1,1,0.1,1.4c0.1,0.4,0.5,0.6,0.8,0.9c0.4,0.4,0.4,1,0.2,1.5
	c0,0.2-0.1,0.4-0.2,0.5c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.3-0.1,0.7-0.2,1c-0.1,0.1-0.2,0.2-0.2,0.3c-0.1,0.2-0.1,0.5-0.2,0.7
	c-0.1,0.3-0.2,0.5-0.2,0.8c-0.1,0.5-0.1,1.1-0.2,1.7c-0.1,0.4-0.1,0.8-0.1,1.2c0.1,1.6,0.4,3.1,0.8,4.6v0c1,0.5,2.2,1.1,3.1,1.3
	c1.6,0.2,3.4,0.9,4,1.1c0.6,0.2,0.9,0.6,2.6,0c1.7-0.6,2.8-0.5,2.8-1.6c0-1.2,0.5-6.8,0.7-9.1c0.2-2.3,0-6,1-5.8
	c1.1,0.1,2.8,0.3,4.5,0.2c1.7-0.1,6.2,0,6.3,0.4c0.1,0.5,0.3,1.1,0.6,1.2c0.2,0.1,0.7,1.1,0.4,2.2c-0.3,1.1-0.5,3.5-0.6,3.8
	c0,0.3-0.3,0.9,0.6,1c0.9,0.1,3.1,0.7,4.1,0.7c1.1,0.1,2.4,0.7,2.8,1.2c0.4,0.6,3.9,5.7,4.7,6.8c0.7,1.2,3.8,6.5,5.3,6.5
	c0.9,0,1.5,0.4,2.2,0.4c0.5,0,1.1-0.1,1.9-0.7c2.1-1.4,3.1-1.4,3.7-2.5c0.6-1.1,4.3-5.9,5.7-6.1c1.4-0.2,2.5,1.3,4.2,1.2
	c1.7-0.2,5.8-2,6.9-2.9c0.9-0.7,4.5-1.9,7-0.6c0.4-0.1,0.8-0.2,1.2-0.2c1.2-0.2,2.3,0.8,2.7,1.6c0.4,0.7,1,0.4,1.3,0
	c0.2-0.4,1.8-0.4,2.7,0.1c0.9,0.5,4.1,0.7,5.7,0.7c1.6,0,5.8,0.5,7.6,1c1.8,0.5,4.8-0.5,6.1-1.7c1.3-1.1,2.4,0.6,3.9,0.4
	c1.5-0.2,2.4,0,3.4,0.3c1,0.3,1.6,0.4,2.6-0.6c1-1,4.4,1.4,6,1.4c1.7,0,6.2-1,7.7-1.3c1.6-0.4,5-4.1,5.3-4.5c0.3-0.4,1.7-3,2.4-4.7
	c0.6-1.7,1.9-7.3,2.3-8.3c0.4-1.1,0.9-3.6,0.6-6.1c-0.3-2.5,0.4-3.2,1.7-4.5c1.2-1.2,4.1-4.3,4.3-5.1c0.1-0.8-2.4-4.7-2.7-5.9
	c-0.2-1.2,1.8-2.5,3-3.4c1.3-1,0.2-3.6-0.4-4.8C289.4,305.2,290.3,304.4,291,303z M260.3,306.6c-0.4,0.9-1,1.8-1.7,2.5
	c-0.4,0.4-0.7,0.7-1,1.2c-0.3,0.4-0.5,0.9-0.8,1.4c-0.1,0.2-0.3,0.6-0.6,0.6c-0.2,0-0.3-0.2-0.3-0.4c0-0.1,0-0.1,0.1-0.2
	c0-0.1,0-0.2,0-0.2c0-0.2,0-0.3,0-0.5c0-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.2-0.8,0.3-1.1c0.1-0.3-0.1-0.6-0.4-0.7
	c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.1-0.4-0.2-0.4-0.4c-0.1-0.2,0.1-0.5,0.2-0.7c0.2-0.3,0.4-0.6,0.7-0.9c0.3-0.3,0.4-0.7,0.6-1.1
	c0.1-0.2,0.1-0.4,0.2-0.7c0.1-0.2,0.1-0.4,0.2-0.5c0.2-0.3,0.5,0,0.6,0.2c0.2,0.2,0.5,0.6,0.8,0.6c0.1,0,0.3-0.2,0.4-0.3
	c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.4-0.5,0.7-0.3c0.4,0.3,0.5,0.8,0.6,1.2C260.7,305.6,260.5,306.1,260.3,306.6z">
        <title>Castelo Branco</title>
    </path>
	{CircledNumber}
	</g>
            )
    }
}