import React from 'react';
import { District } from '../district';

export class Lisboa extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Lisboa', props.data)
		}
	}

    render () {
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="35" cy="440" r="15" fill="#187795" />
				<text x="35" y="440"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('lisboa') } name="lisboa" d="M81.1,433.4c-1.4,1.3-6.7,5.1-6.7,5.1s-4.4-1.4-7.6,2.2c-0.6-0.3-1.2-0.6-1.7,0.4c-0.7,1.2-3.8,7-4.8,8
		c-1.1,1-3.9,5.6-4.6,7.4c-0.7,1.8-2.1,3.7-2.3,5.3c-0.2,1.6-0.5,2.9-1,3.4c-0.6,0.5-2.3,2.3-2.7,2.7c-0.4,0.4-2-0.2-2,1.2
		c0,1.4,0.5,3,0.3,3.9c-0.1,0.9-1.3,4.8-2.2,5.4c-0.9,0.7-3.9,2.8-4.4,3.8c-0.5,1-1.8,3.6-4.1,3.5c-2.3-0.1-11-0.5-12.3,0.1
		c-1.2,0.5-5,2.3-5.9,2.2c-0.8-0.1-1.5-0.3-2.5-1.3c-1-0.9-2.9-2.7-3.9-2.6c-1,0.1-3.3,2-4.4,1.8c-1.1-0.2-4.8-2.7-4.7-3.8
		c0.1-1.1,1.7-3.1,1.3-4.2c-0.4-1.1-3.8-4.2-3.5-5.8c0.3-1.5,5.1-4,5.3-6.4c0.2-2.3,0-4.7,0.5-5.4c0.5-0.7,1.2-1.2,1.1-2.3
		c-0.1-1.1-0.2-2.9,0.2-3.5c0.5-0.7,1.3-1.9,1.3-3.7c0-1.8-0.3-6.5,0.1-7.7c0.4-1.2,0.9-2.8,0.8-4.3c-0.1-1.5-0.7-6.5,0-7.7
		c0.7-1.2,1.6-2.8,1.8-4.1c0.2-1.2,0.8-3.9,1.5-4.4c0.7-0.5,3.5-2.2,3.8-3.5c0.3-1.3,1.5-4.7,1.7-5.4c0.2-0.8,0.7-2.3,0.7-3.7
		c-0.1-1.4-0.4-2.7,0-4c0.4-1.2,1.3-4.9,0.6-5.4c0.9-0.9,2.4-2.2,2.9-1.9c0.8,0.5,3,1.5,3.6,0.6c0.6-0.9,2.3-2.7,3.7-2.6
		c1.4,0,5.5,0.1,5.5,1.5c-0.1,1.3-0.3,6.4,1,6.5c1.3,0.1,1.5,0.9,1.3,1.8c-0.1,0.9-0.6,3.2,0.4,3.3c1,0,2.6,0.5,2.9-0.8
		c0.2-1.3,0.9-4.5,2.2-5.8c1.3-1.3,4.4-4.9,5.5-5.4c1.1-0.5,2.7-1.9,5.5-1.5c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.3,0.3,0.5
		c0.5,1,1.5,2.4,2.3,2.5c1.2,0.2,3.3,0.5,2.9,2.3c-0.4,1.9-1.1,4,0.2,4.1c1.3,0.1,2.7,0.1,3.8,0.2c1.1,0.1,6.5,0.3,7.6,0.3
		c1.1,0,1.9,0.2,2.3,0.7c0.4,0.5,3.2,3,2.8,4c-0.4,1-1,3.2-2.2,3.1c-1.2,0-8.3,0.2-7.9,0.7c0.4,0.6,5.1,6.3,6.4,8.1
		c1.3,1.8,3,3.8,3.6,4.4C78.5,428,82.5,432.2,81.1,433.4z"><title>Lisboa</title></path>
		{CircledNumber}
		</g>
            )
    }
}