import React from 'react';
import styled from "styled-components"

const StyledList = styled.ul`
&& {
    font-family: Ubuntu, sans-serif;

    span {
        font-weight: bold;
    }
}
`
export default function AdditionalData(props) {
    return (
        <StyledList>
            {props.personDetails.professionalCard && <li><span>Número da cédula:</span> {props.personDetails.professionalCard}</li>}
            {props.personDetails.apfId && <li><span>Número de Sócio da APF:</span> {props.personDetails.apfId}</li>}
            {props.personDetails.academics && <li><span>Grau académico:</span> {props.personDetails.academics}</li>}
            {props.personDetails.workYears && <li><span>Anos de experiência:</span> {props.personDetails.workYears}</li>}
            {props.personDetails.specialty && <li><span>Especialidade:</span> {props.personDetails.specialty}</li>}
            {props.personDetails.conditionTypes && <li><span>Condições:</span> {props.personDetails.conditionTypes}</li>}
            {props.personDetails.specialInterest && <li><span>Interesse em:</span> {props.personDetails.specialInterest}</li>}
            {props.personDetails.workPlace && <li><span>Locais de trabalho:</span> {props.personDetails.workPlace}</li>}
            {props.personDetails.actingRegions && <li><span>Concelhos onde trabalha:</span> {props.personDetails.actingRegions}</li>}
            {props.personDetails.links && <li><span>Links:</span> <a href={props.personDetails.links}>{props.personDetails.links}</a></li>}

        </StyledList>
    )
} 