import React from 'react';
import styled from 'styled-components';
import SVG from './svg';

import { VianaDoCastelo } from './districts/viana-do-castelo'
import { Braga } from './districts/braga'
import { Braganca } from './districts/braganca'
import { VilaReal } from './districts/vila-real'
import { Porto } from './districts/porto'
import { CasteloBranco } from './districts/castelo-branco'
import { Portalegre } from './districts/portalegre'
import { Santarem } from './districts/santarem'
import { Evora } from './districts/evora'
import { Setubal } from './districts/setubal'
import { Beja } from './districts/beja'
import { Aveiro } from './districts/aveiro'
import { Coimbra } from './districts/coimbra'
import { Leiria } from './districts/leiria'
import { Lisboa } from './districts/lisboa'
import { Faro } from './districts/faro'
import { Guarda } from './districts/guarda'
import { Viseu } from './districts/viseu'

const Svg = styled(SVG)`
	height: 80vh;
	background-color: #ebf7f7;
    .st0{ 
        fill:#3E363F;
		stroke: #ebf7f7;
		stroke-width: 1;
		stroke-miterlimit: 10;
        transition: fill 1s;

        :hover {
            fill: #187795;
            cursor: pointer;
		}
		
		&.disabled {
			opacity: 0.4;
			pointer-events: none;
		}
	}
	text {
		stroke: #ebf7f7;
		pointer-events: none;
	}
	circle {
		pointer-events: none;
	}
	svg {
		pointer-events: none;
	}
`

export class Map extends React.Component {

	constructor(props) {
		super(props);
		console.log(props);
		this.data = props.data;
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick() {
		this.props.click();
	}

	render() {
		return (
		<Svg viewBox="0 0 360.5 730.4">
			<VianaDoCastelo onClick={this.handleClick} data={this.data} />
			<Braga data={this.data} />
			<Braganca data={this.data} />
			<VilaReal data={this.data} />
			<Porto data={this.data} />
			<Guarda data={this.data} />
			<Viseu data={this.data} />
			<CasteloBranco data={this.data} />
			<Portalegre data={this.data} />
			<Santarem data={this.data} />
			<Evora data={this.data} />
			<Setubal data={this.data} />
			<Beja data={this.data} />
			<Aveiro data={this.data}/>
			<Coimbra data={this.data} />
			<Leiria data={this.data} />
			<Lisboa data={this.data} />
			<Faro data={this.data} />
		</Svg>
	)}
}
