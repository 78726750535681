import React from 'react';
import { District } from '../district';

export class VilaReal extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Vila Real', props.data)
		}
	}

    render () {
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="215" cy="90" r="15" fill="#187795" />
				<text x="215" y="90"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('vila real') } name="vilareal" d="M258.5,52.8c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.4-0.2-0.8-0.3-1.2c-0.1-0.3-0.2-0.7-0.4-1
	c-0.2-0.3-0.4-0.5-0.6-0.8c-0.1-0.1-0.2-0.3-0.4-0.4c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1-0.2-0.3-0.4-0.5c-0.2-0.2-0.4-0.5-0.5-0.7
	c-0.3-0.4-0.5-0.8-0.7-1.2c-0.2-0.2-0.4-0.4-0.4-0.7c0-0.1,0.1-0.5,0-0.5c0-0.2,0-0.5-0.1-0.8c0-0.3,0-0.5-0.1-0.8
	c0-0.5,0-1-0.1-1.5c0-0.5,0-1-0.1-1.5c0-0.2,0-0.4,0-0.6c0-0.3-0.1-0.6-0.1-0.9c0,0,0-1.6-0.6-2c-0.2,0.3-0.4,0.4-0.4,0.4
	s-11.2,6.5-11.3,6.5c-0.1,0-4.7,1-4.7,1l-1.6-1.7c0,0-0.3,0.2-1.3,0.8c-1,0.6-1.1,1.5-1.7,2.9c-0.6,1.5-1.6,2-1.8,2
	c-0.2,0-2.6-2.6-3.8-3.5c-1.1-0.8-2-2.4-1.8-3.5c0.2-1.1-4.2-2.3-5.3-1.5c-1.1,0.8,0.7,2,0.6,3c0,1.1-4.4,1.3-6.2,1.5
	c-1.8,0.1-2.7,1.7-4.6,1.4c-2-0.3,0.3-4.7,2.2-5.8c1.9-1-2.8-2.2-2.8-2.2l-4.8,0.7l-3.2-3.1c0,0-13.7,2.5-13.7,2.6c-0.1,0-4,4-4,4
	l-1.4-0.2l-1.6-11.7l-3.7,0.6v5.1l-3.9,0.8l-2.8,2.7c0,0-0.1,3.7-0.1,3.8s-4.9,2.5-5.8,3c0,0,0.7,0.7,2,1.5c1.2,0.8,0.6,3,0.6,3
	c0,0.5,0,1,0,1.5c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.3,0.5l-0.5,0.5c-0.2,0.1-0.3,0.3-0.5,0.5c-0.2,0.1-0.4,0.3-0.5,0.5
	c-0.2,0.4-0.5,0.9-0.8,1.2c-0.6,0.8-1.2,1.7-1.8,2.5c-0.4,0.6-0.9,1.1-1.4,1.6c-0.4,0.4-0.7,0.8-1,1.4c-0.1,0.3-0.3,0.8-0.1,1.2
	c0.1,0.3,0.3,0.6,0.5,0.9c0.2,0.4,0.6,0.5,1,0.7c0.6,0.1,1.2,0,1.8-0.1c0.3,0,0.7-0.1,1-0.3c0.3-0.2,0.6-0.4,1-0.6
	c0.3-0.2,0.6-0.3,1-0.4c0.3-0.1,0.5-0.2,0.8-0.1c0.1,0,0.3,0,0.5,0c0.2,0,0.4,0,0.5,0.1c0.2,0,0.4,0.1,0.5,0.2
	c0.2,0,0.3,0.1,0.5,0.1c0.4,0.1,0.7,0.3,1,0.5c0.1,0,0.1,0.1,0.2,0.2c0.1,0,0.1,0.1,0.2,0.1c0.2,0.2,0.4,0.6,0.3,1
	c-0.1,0.5,0,1,0.1,1.4c0,0.5,0.1,1,0.2,1.5c0,0.2,0,0.5,0,0.8c0,0.3,0,0.6,0,0.9c0,0.6,0.1,1.1,0.1,1.7c0,0.2,0.1,0.5,0.2,0.7
	c0.2,0.5,0.3,1,0.4,1.5c0.1,0.4,0.1,0.7,0.6,0.9c0.4,0.1,0.7,0.1,1,0.2c0.4,0.1,0.8,0.2,1.3,0.3c0.2,0,0.4,0.1,0.5,0.1
	c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.2,0.5,0.3c0.1,0.1,0.4,0.3,0.5,0.3c0,0,0,0,0-0.1c0.2,0.1,0.3,0.3,0.5,0.4
	c0.3,0.1,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.1,0.8,0.2c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0.1,0.4,0.2,0.6,0.3c0.5,0.3,1.1,0.6,1.7,0.6
	c0.2,0,0.4,0.1,0.6,0c0.2-0.1,0.4-0.3,0.5-0.5c0.1-0.2,0.2-0.4,0.5-0.5c0.4-0.3,0.9-0.6,1.3-1c0.5-0.5,0.9-1,1.3-1.5
	c0.4-0.4,0.8-1,1.5-0.9c0.5,0,1,0.5,1.4,0.8c0.5,0.5,1.1,0.9,1.6,1.5c0.5,0.6,0.7,1.3,0.9,2c0.2,0.7,0.5,1.5,0.8,2.2
	c0.3,0.6,0.7,1.3,0.7,2c0,0.5-0.3,1-0.6,1.5c-0.1,0.2-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.3-0.5,0.5c-0.1,0-0.1,0.1-0.2,0.1
	c0,0-0.1,0.1-0.1,0.2c-0.4,0.4-0.6,1-1.1,1.3c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0.1-0.5,0.4-0.7,0.5c-0.3,0.1-0.5,0.3-0.7,0.5
	c-0.4,0.2-0.7,0.5-1,0.7c-0.1,0.1-0.2,0.2-0.4,0.4c-0.1,0.1-0.2,0.1-0.4,0.2c-0.4,0.2-0.8,0.5-1.2,0.7c-0.4,0.2-0.7,0.5-1.1,0.7
	c-0.1,0.1-0.3,0.2-0.4,0.3c-0.7,0.4-1,1-1.5,1.6c-0.3,0.3-0.5,0.7-0.7,1c-0.2,0.3-0.4,0.6-0.5,1c-0.1,0.4-0.1,0.7-0.2,1
	c0,0.1-0.1,0.3-0.1,0.5c0,0.1-0.1,0.2-0.1,0.4c-0.1,0.3-0.2,0.6-0.3,0.9c0,0.1-0.1,0.3-0.2,0.4c-0.2,0.3-0.5,0.6-0.8,0.9
	c-0.2,0.2-0.4,0.3-0.5,0.5c-0.1,0.3-0.2,0.6-0.5,0.8c-0.2,0.2-0.2,0.5-0.4,0.6c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.2,0.4-0.3,0.5
	c-0.2,0.3-0.4,0.7-0.6,1.1c-0.2,0.3-0.5,0.6-0.8,0.8c-0.3,0.2-0.6,0.4-0.8,0.7c-0.1,0.2-0.3,0.6-0.3,0.9v1.1c0,0.4,0,0.7,0,1
	c0,0.9,0,1.7-0.2,2.5c0.1,0,0.3,0.1,0.4,0.2c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.2,0.2,0.3,0.4,0.5c0.1,0.1,0.2,0.3,0.4,0.4
	c0.2,0.1,0.4,0.2,0.4,0.4c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.4-0.2,1.3,0.1,1.6c0.2,0.2,0.4,0.2,0.7,0.2
	c0.4,0.1,0.8,0.4,1.1,0.6c0.4,0.2,0.9,0.5,1.3,0.8c0.2,0.1,0.4,0.3,0.6,0.5c0.1,0.1,0.1,0.3,0.2,0.5c0.2,0.5,0.2,0.8,0.2,1.2
	c0,0.5-0.3,0.8-0.3,1.2c0,0.4-0.1,0.9,0,1.3c0.1,0.4,0.2,0.8,0.4,1.2c0.3,0.6,0.7,1,1.2,1.3c0.5,0.4,1.1,0.6,1.5,1.1
	c0,0.2,0,0.4,0,0.6c0,0.1-0.2,0.3-0.2,0.4c0,0.1,0,0.2,0,0.4c0,0.3,0,0.6-0.1,1c-0.1,0.6-0.2,1.2-0.2,1.9c0,0.3,0,0.6,0,0.9
	c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.5-0.1,1-0.1,1.4c-0.1,1.2-0.2,2.4-0.8,3.5c-0.2,0.5-0.2,1-0.3,1.5c-0.1,0.4-0.1,0.9-0.2,1.3
	c-0.1,0.5-0.1,1-0.3,1.5c-0.2,0.5-0.3,1-0.3,1.5c0,0.2,0,0.5,0,0.7c0,0,0.4,1.6,0.5,1.8c0.2-0.2,0.3-0.6,0.5-0.8
	c0.1-0.2,0.2-0.3,0.3-0.5c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.3-0.3,0.5-0.5c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.2,0.3-0.3,0.4-0.5
	c0.2-0.1,0.3-0.3,0.5-0.4c0.2-0.2,0.5-0.4,0.7-0.6c0.2-0.1,0.5-0.4,0.7-0.4c0.2-0.1,0.5,0,0.7,0c0.6,0.1,1.1,0.4,1.7,0.7
	c0.2,0.1,0.4,0.2,0.7,0.3c0.2,0,0.4,0,0.6,0c0.3,0,0.5,0,0.7-0.1c0.4-0.1,0.7-0.1,1-0.4c0.4-0.2,0.7-0.5,1.1-0.7
	c0.2-0.1,0.4-0.1,0.6-0.2c0.2,0,0.5-0.1,0.7-0.1c0.4-0.1,0.8-0.1,1.2-0.1c0.5,0,0.9-0.1,1.4-0.1c0.4,0,0.9,0.1,1.3,0.4
	c0.1,0.1,0.3,0.2,0.5,0.4c0.2,0.1,0.4,0.3,0.6,0.4c0.3,0.2,0.7,0.5,1,0.7c0.7,0.5,1.6,0.5,2.3,1c0.5,0.4,1.2,0.4,1.8,0.4
	c0.6,0.1,1.3-0.4,1.8-0.7c0.3-0.2,0.5-0.5,0.6-0.7c0.2-0.4,0.5-0.6,0.7-0.8c0.2-0.2,0.5-0.4,0.9-0.5c0.4-0.1,0.8,0,1.2,0
	c0.2,0,0.4,0,0.5,0c0.2,0,0.3,0.1,0.5,0.2c0.3,0.1,0.6,0.4,0.9,0.6c0.3,0.3,0.5,0.4,0.9,0.4c0.4,0,0.7,0,1.1,0c0.4,0,0.7,0,1.1-0.1
	c0.3-0.1,0.6-0.2,0.9-0.5c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.4-0.2,0.6-0.3c0.6-0.2,1.2-0.5,1.7-0.9c0.6-0.5,1.1-1,1.7-1.3
	c0.7-0.4,1.3-0.8,1.9-1.3c0.5-0.4,0.9-0.9,1.3-1.3c0.9-1,2.2-1.8,3.5-1.9c0.9,0,1.8-0.1,2.7-0.1c0.4,0,0.9,0,1.3,0.1
	c0.4,0,0.6,0,1,0h1.5c0.2,0,0.4,0.1,0.6,0.2c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0,0.4,0.1,0.6,0.2c0-0.1,0-0.2,0.1-0.4
	c0.1-0.3,0.1-0.6,0.2-0.9c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.2-0.8,0.3-1.2c0.1-0.4,0.2-0.9,0.3-1.3c0-0.1,0-0.1,0.1-0.2
	c0.1-0.4,0.3-0.8,0.4-1.2c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.4,0.2-0.8,0.3-1.2c0.2-0.9,0.4-1.9,0.7-2.8c0.1-0.2,0.1-0.4,0.2-0.5
	c0.1-0.2,0.1-0.5,0.2-0.7c0.1-0.3,0.1-0.6,0.1-0.9c0.1-0.2,0.1-0.5,0.2-0.8c0.1-0.2,0.2-0.2,0.4-0.4c0.2-0.1,0.2-0.4,0.4-0.6
	c0.1-0.1,0.3-0.3,0.5-0.4c0.2-0.1,0.5-0.1,0.6-0.3c0.1-0.1,0.2-0.2,0.4-0.2c0.2-0.1,0.4-0.1,0.6-0.3c0.2-0.1,0.4-0.2,0.5-0.4
	c0.4-0.2,0.8-0.4,1.2-0.8c0.5-0.5,1-1.1,1.2-1.8c0.2-0.9-0.2-1.6-0.7-2.3c-0.2-0.4-0.4-0.7-0.8-1c-0.3-0.2-0.7-0.4-1.1-0.7
	c-0.4-0.3-0.8-0.5-1.1-1c-0.1-0.2-0.1-0.3-0.1-0.5c0-1.2-0.1-2.3-0.1-3.5c0-0.3,0-0.7-0.1-1c-0.1-1.2-0.1-2.4,0-3.6
	c0-0.6-0.1-1.1,0-1.7c0-0.3,0.1-0.6,0.1-0.8c0.1-0.2,0.2-0.4,0.5-0.5c0.3-0.2,0.6-0.2,1-0.4c0.2-0.1,0.5-0.2,0.7-0.3
	c0.2-0.1,0.5-0.2,0.7-0.2c0.2,0,0.4-0.1,0.6-0.1c0.4-0.2,0.8-0.5,1.1-0.9c0.2-0.3,0.2-0.6,0.2-1c0-0.4,0.1-0.8,0.1-1.1
	c0-0.2,0-0.4,0.1-0.5c0.1-0.4,0.2-0.8,0.4-1.1c0.4-0.7,0.7-1.5,0.8-2.3c0.1-0.7,0.7-0.9,1.3-1c0.2-0.1,0.5-0.1,0.7-0.2
	c0.3-0.1,0.5-0.2,0.8-0.2c0.7,0,1.5,0,2.2,0c0.6,0,1.2,0.1,1.7-0.2c0.2-0.1,0.4-0.3,0.5-0.6c0.1-0.4,0-0.9,0.1-1.3
	c0-0.1,0.1-0.2,0.2-0.4c0.1-0.2,0.1-0.4,0.1-0.6c-0.1-0.4-0.4-0.7-0.6-1c-0.2-0.4-0.4-0.8-0.5-1.2c-0.2-0.6-0.2-1.2-0.5-1.8
	c-0.1-0.4-0.4-0.7-0.5-1.1c-0.1-0.3,0-0.6,0-0.9c0-0.2-0.1-0.4,0-0.6c0.1-0.5,0.4-1,0.5-1.6c0.1-0.6,0.4-1.1,0.6-1.7
	c0.1-0.2,0.3-0.5,0.4-0.7c0.1-0.3,0.4-0.5,0.5-0.8c0.1-0.4,0.2-0.9,0.4-1.3c0.1-0.3,0.1-0.5,0.3-0.7c0.1-0.2,0.2-0.4,0.3-0.6
	c0.2-0.6,0.5-1.2,0.6-1.8c0.1-0.3,0.1-0.7,0.2-1c0.1-0.2,0.2-0.4,0.3-0.5c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.3,0.2-0.7,0.4-1
	c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.3,0.1-0.5c0.1-0.4,0.2-0.8,0.3-1.2c0-0.2,0-0.5,0-0.7c0-0.4,0.1-0.7,0.3-1
	c0.2-0.5,0.2-1.1,0.3-1.7c0-0.2,0.1-0.5,0.3-0.7c0.2-0.4,0.3-0.7,0.5-1.1c0.1-0.5,0.3-0.9,0.5-1.3c0.1-0.1,0.2-0.3,0.3-0.4
	c0.1-0.1,0.2-0.2,0.2-0.4c0.1-0.1,0.2-0.2,0.4-0.4c0.1-0.1,0.1-0.3,0.2-0.5c0.2-0.2,0.2-0.6,0.2-0.9
	C258.4,53.5,258.6,53.1,258.5,52.8z M195.8,54.8c-0.2,0.2-0.5,0.4-0.7,0.6c-0.5,0.4-1.1,0.7-1.4,1.3c-0.3,0.4-0.5,0.9-0.8,1.2
	c-0.4,0.4-0.9,0.5-1.4,0.6c-0.4,0.1-0.8,0-1.1,0c-0.4,0-0.8,0.1-1.2,0.2c-0.6,0.1-1.2,0.4-1.7,0.2c-0.1,0-0.3-0.1-0.4-0.1
	c-0.3-0.1-0.6-0.2-0.8-0.2c-0.1,0-0.2,0-0.3,0c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0-0.5,0-0.5-0.2
	c0-0.1-0.1-0.1,0-0.2c0-0.1,0-0.2-0.1-0.3c0-0.2,0-0.4,0-0.6c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0.3-0.3,0.5-0.4c0.9-0.3,1.8-0.5,2.7-0.7
	c1.1-0.1,2.2-0.3,3.3-0.4c0.9,0,1.9,0,2.7-0.5c0.4-0.2,0.7-0.4,1.1-0.5c0.2-0.1,0.9-0.2,1,0.1C196,54.6,195.8,54.8,195.8,54.8z">
        <title>Vila Real</title>
    </path>
	{CircledNumber}
	</g>
            )
    }
}