import React from 'react';
import { District } from '../district';

export class Leiria extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Leiria', props.data)
		}
	}

    render () {
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="75" cy="350" r="15" fill="#187795" />
				<text x="75" y="350"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('leiria') } name="leiria" d="M156.9,312.3c0,0.5,0,0.9-0.1,1.3c-0.1,0.5-0.3,1-0.6,1.5c-0.2,0.4-0.4,0.9-0.7,1.3c-0.4,0.5-0.7,0.9-1.1,1.3
		c-0.4,0.4-0.8,0.7-1.3,1.1c-0.4,0.3-0.7,0.7-1,1c-0.4,0.4-0.8,0.8-1.2,1.1c-0.8,0.7-1.8,1.2-2.8,1.4c-0.3,0-0.6,0-1,0
		c-0.3,0-0.6,0.1-0.9,0.2c-0.4,0.1-0.8,0.2-1.2,0.2c-0.7,0.2-1.5,0.4-2.2,0.5c-0.4,0.1-0.7,0.1-1,0.1c-0.5,0.1-0.9,0.2-1.3,0.5
		c-0.7,0.5-0.7,1.3-0.8,2c-0.2,0.7-0.2,1.5-0.5,2.2c-0.1,0.6-0.5,1.1-0.7,1.7c-1,0.1-2,0.2-2.3,0.2c-0.8,0.1-0.9,1.4-1,2.5
		c-0.1,1.1-1.6,0.8-3.4,0.9c-1.8,0.1-12.2,2.1-12.2,2.1s-2.3-9-2.8-10.5c-0.6-1.5-2.6,0-3.7,0.4c-1.2,0.4-1.5,1.1-4.1,1.9
		c-2.7,0.7-2.1,1.7-3.3,2.5c-1.2,0.7-7.8,2.1-9.3,2.6c-1.5,0.4-1.2,1.4-1.2,1.4s0.2,3.4,0.2,4.8c0,1.3,1.8,3.9,2.5,4.7
		c0.7,0.9,0.3,1.8,0,2.3c-0.3,0.5-0.5,1.1-1,2.5c-0.5,1.5-3.2,2.9-5.5,4.4c-2.3,1.4,1.1,6.1,2,8c0.8,1.9-0.2,5.6-0.4,6.4
		c-0.2,0.8-3.6,0-4.2,0c-0.6-0.1-1.2,3.2-1.6,4.6c-0.4,1.3-1.9,1-3.3,1c-1.3,0-6.3,1-7.8,1.1c-1.6,0.1-5,1.8-5.9,2.2
		c-1,0.4-3,6-3.5,6.9c-0.5,1-2.9,2.3-4.2,2.5c-1.2,0.2-2.2,1.6-2.9,1.9c-0.7,0.4-0.4,5.5-0.3,7.3c0,1.3-1.4,2-3.2,2.2
		c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1,0-0.2,0-0.4-0.1c-2.7-0.5-4.4,1-5.5,1.5c-1.1,0.5-4.2,4.1-5.5,5.4c-1.3,1.3-1.9,4.5-2.2,5.8
		c-0.3,1.3-1.9,0.8-2.9,0.8c-1-0.1-0.5-2.3-0.4-3.3c0.1-0.9-0.1-1.7-1.3-1.8c-1.3-0.1-1.1-5.2-1-6.5c0.1-1.3-4.1-1.4-5.5-1.5
		c-1.4,0-3.1,1.8-3.7,2.6c-0.6,0.8-2.8-0.1-3.6-0.6c-0.6-0.3-2,1-2.9,1.9c0,0,0,0,0,0c-0.7-0.5-3-1.6-3-2.7c0-1.1,1.3-2.6,0.4-2.9
		c-1-0.3-1.5-1.3-2.1-0.8c-0.7,0.5-1.2,0.6-1.7,0.2c-0.5-0.3-1.9-1.6-1.6-1.8c0.4-0.3,0.8-1.2,1.3-1.2c0.5,0.1,1.2,0.5,1.8-0.2
		c0.6-0.7,0.8-0.9,1-0.2c0.2,0.7,0.4,0.8,1,1c0.6,0.2,1.4,0.5,1.5-0.2c0.1-0.7-0.1-1.8,0.6-2.1c0.7-0.2,0.9,0.4,0.9,0.8
		c0.1,0.4,1,1.4,2.1,0c1.1-1.4,5.7-6.1,6.6-7.4c0.9-1.3,1.5-1.6,1.8-1c0.4,0.6,1,1.2,1.5,1.1c0.5-0.2,1.8-0.2,1.5,0.3
		c-0.4,0.5-0.8,0.7-0.3,0.8c0.6,0,0.7,0.8,0.1,0.9c-0.6,0-0.8,0.4-0.6,0.4c0.2,0.1,0.6,0.9,0.1,1c-0.6,0.1-0.9,0.4-0.5,0.5
		c0.4,0.1-0.5,1.1-0.5,1.6c0,0.5,0.4,1.7,1,1.3c0.5-0.5,1.2-2.2,1.7-1.8c0.6,0.3,1.6,1,1.6,0.5c0-0.6,0-1.2,0.4-1.1
		c0.4,0.1,1.2,0.5,1.2,0c0-0.5,0.6-1.4-0.5-1.4c-1,0-1.8-0.2-1.9-0.8c-0.1-0.7-0.1-3.1-0.9-3.5c-0.8-0.4-1.8-1-1.8-1.7
		c0-0.6,0.4-1.2,0.9-1.4c0.5-0.2,1.8-1.2,2-1.6c0.2-0.5,3-4.4,3.4-4.4c0.5,0,1.8-0.2,1.8-1c0-0.8,0.6-1.7,0.9-2
		c0.3-0.3,3.9-3.9,4-4.5c0.1-0.6,0.9-2.2,1.4-2.5c0.5-0.3,2.9-1.9,2.3-2.7c-0.7-0.8-1.9-1.9-1.5-2.3c0.4-0.5,4.8-9,4.9-10.5
		c0.1-1.6,0.3-6,0.8-7.3c0.5-1.3,2.7-6,3.3-7c0.7-1,3.3-5.3,3.6-6c0.3-0.7,2.4-5.1,2.8-6.2c0.4-1.1,2.3-5.2,2.7-6.3
		c0.4-1,3.2-11,3.2-11s1.4-5,2.5-9.2c0.3,0.3,0.6,0.5,1,0.7c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.5-0.1,0.6,0c0.1,0.1,0.2,0.2,0.4,0.3
		c0.4,0.2,0.6,0.6,1,1c0.2,0.2,0.5,0.5,0.7,0.7c0.2,0.2,0.5,0.4,0.7,0.6c0.4,0.4,0.6,0.8,0.9,1.2c0.4,0.4,1,0.4,1.4,0.2
		c0.2-0.1,0.5-0.1,0.7-0.3c0.2-0.2,0.4-0.4,0.5-0.6c0.2-0.2,0.3-0.4,0.6-0.6c0.2-0.2,0.4-0.3,0.6-0.5c0.5-0.4,1-0.6,1.5-1
		c0.4-0.4,1.1-0.3,1.6-0.3c0.3,0,0.7-0.1,1,0c0.2,0.1,0.5,0.2,0.8,0.2h0.5c0.3,0.1,0.7,0.1,1.1,0.1c0.2,0,0.5,0,0.7,0
		c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.4,0.1c0.4,0.1,0.9,0.1,1.3,0c0.1,0,0.2-0.1,0.4-0.1c0.3-0.1,0.6,0,0.9,0c0.3,0,0.6,0.1,0.9,0.2
		c0.2,0.1,0.5,0.3,0.7,0.4c0.2,0.2,0.5,0.4,0.7,0.6c0.3,0.2,0.5,0.4,0.7,0.7c0.2,0.3,0.4,0.6,0.5,1c0.1,0.2,0.2,0.4,0.3,0.5
		c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.3,0.4,0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.3,0.5,0.7,0.7,1c0.3,0.2,0.3,0.7,0.4,1
		c0.1,0.2,0.2,0.5,0.3,0.7c0.1,0.2,0.4,0.3,0.6,0.4c0.2,0.1,0.5,0.2,0.8,0.2c0.3,0,0.6-0.1,1-0.2c0.4-0.1,0.7-0.3,1-0.6
		c0.2-0.3,0.4-0.7,0.5-1.1c0.2-0.4,0.4-0.7,0.7-1c0.2-0.2,0.6-0.4,0.8-0.7c0.4-0.5,0.7-1.2,1.2-1.6c0.5-0.3,1-0.4,1.6-0.4
		c0.3,0,0.6-0.1,0.9-0.1c0.2,0,0.5,0,0.6,0.1c0.2,0.1,0.2,0.3,0.3,0.5c0.1,0.3,0.1,0.5,0.1,0.8c0.2,0.8,0.2,1.7,0.2,2.6
		c0,1-0.1,1.9,0,2.9c0,0.2,0,0.4,0.1,0.6c0,0.1,0.1,0.2,0.1,0.4c0.1,0.4,0.2,0.8,0.2,1.3c0,0.2,0,0.5,0.1,0.7
		c0.1,0.4,0.2,0.7,0.6,0.7c0.2,0,0.4,0,0.6,0c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.5-0.1c0.1-0.2,0.3-0.4,0.4-0.6
		c0.1-0.1,0.1-0.4,0.1-0.5c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.4,0.2-0.5c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.2-0.3,0.3-0.5
		c0.1-0.1,0.1-0.2,0.1-0.3c0.1-0.4,0.3-0.8,0.5-1.1c0.1-0.2,0.4-0.4,0.5-0.7c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.3,0.3-0.4,0.5-0.5
		c0.1-0.1,0.2-0.1,0.4-0.2c0.1,0,0.2,0,0.4,0c0.4,0,0.7,0,1-0.1c0.2-0.1,0.5-0.2,0.8-0.3c0.2-0.1,0.5-0.3,0.7-0.3
		c0.4-0.1,0.8-0.1,1.2-0.2c0.2,0,0.4,0,0.6-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1-0.1,0.2-0.1
		c0.2-0.1,0.4-0.1,0.6-0.2c0.4-0.1,0.8-0.1,1.2-0.2c0.6-0.2,1.1-0.7,1.8-0.8c0.3,0,0.6,0,0.7,0.2c0.2,0.2,0.3,0.7,0.3,1
		c0.1,0.9,0.2,1.8,0.4,2.7c0.2,0.8,0.5,1.7,1,2.4c0.2,0.3,0.4,0.6,0.5,1c0.2,0.5,0.2,1,0.2,1.6c0.1,0.7,0,1.7,1,1.8
		c0.3,0,0.7,0,1-0.1c0.4-0.1,0.7-0.1,1.1-0.1c0.3-0.1,0.6-0.2,0.8-0.4c0.2-0.3,0.3-0.6,0.4-1c0.1-0.4,0.2-0.7,0.4-1.1
		c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.3,0.2-0.6,0.4-0.7
		c0.2-0.2,0.6-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2c0.4,0.1,0.8,0.1,1.1,0.3c0.3,0.1,0.5,0.4,0.8,0.5c0.3,0.1,0.5,0,0.7-0.2
		c0.2-0.2,0.4-0.4,0.6-0.5c0.2-0.1,0.4-0.2,0.5-0.5c0-0.4-0.1-0.7-0.1-1.1c-0.1-0.4-0.1-0.7-0.3-1.1c-0.1-0.4-0.3-0.7-0.4-1.1
		c-0.1-0.5-0.1-1-0.3-1.5c-0.1-0.3-0.2-0.6-0.3-1c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.3,0-0.6,0.1-0.8c0.1-0.3,0.3-0.4,0.6-0.5
		c0.3-0.1,0.6-0.2,0.9-0.3c0.2-0.1,0.5-0.3,0.7-0.4c0.2-0.1,0.4-0.2,0.5-0.3c0.2-0.1,0.4-0.3,0.5-0.4c0.1,0,0.1,0,0.1-0.1
		c0.4-0.3,0.8-0.6,1.3-0.9c0.6-0.4,1.2-0.6,1.7-1c0.6-0.3,1.1-0.8,1.6-1.2c0.4-0.3,0.8-0.7,1.3-0.6c0.4,0,0.9,0.3,1.2,0.5
		c0.5,0.2,1.1,0.6,1.6,0.8c0.5,0.2,1,0.4,1.5,0.6c0.1,0,0.4,0.1,0.4,0.3c0.1,0.2,0,0.4-0.1,0.5c-0.3,0.6-0.8,1-0.9,1.7
		c0,0.4,0.1,0.7,0.2,1.1c0.1,0.4,0.3,0.7,0.4,1.1c0.1,0.2,0.2,0.5,0.3,0.7c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.4
		c0,0.2,0.1,0.5,0.2,0.7c0.1,0.2,0.2,0.4,0.3,0.7c0,0.1,0,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2
		c0.1,0.1,0.2,0.2,0.2,0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.2,0.4,0.4,0.8,0.7,1.2c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.1,0.2,0.3,0.3,0.4
		l1.2,1.3c0,0-0.1,0.1-0.1,0.1c-0.6,0.8-0.2,1.8-0.1,2.7c0.1,0.5,0.2,1,0.3,1.5c0.1,0.4,0.1,0.8,0.1,1.2
		C156.9,311.3,156.9,311.8,156.9,312.3z"><title>Leiria</title></path>
		{CircledNumber}
		</g>
            )
    }
}