import React from 'react';
import { District } from '../district';

export class Guarda extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Guarda', props.data)
		}
	}

    render () {
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="260" cy="210" r="15" fill="#187795" />
				<text x="260" y="210"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('guarda') } name="guarda" d="M300.6,253.9c0,0.9,0,3.7-1.6,4.2c-1.6,0.5-3.9,0.7-4.3,1.9c-0.4,1.2-2.9,7.2-2.9,7.2s-4.1-0.5-6.4,0.1
	c-1.7-1.6-4.7-4.5-5.2-5.1c-0.7-0.9-1.5-0.7-1.5-0.7s-5-0.3-6.8-0.2c-1.7,0.1-2.5-0.8-3.2-1.3c-0.6-0.5-2.8-1.8-3.4-1.9
	c-0.6-0.2-1.9,1.2-2.3,1.5c-0.5,0.2-0.8,1.3-1.1,1.9c-0.2,0.7-0.8,0.6-1.5,0.8c-0.6,0.2-4.1,0.6-5.2,0.5c-1.1-0.1-5.3,0.8-6,0.7
	c-0.7-0.1-6.6-1-7.3-0.9c-0.7,0.1,0.9-2.4,1.7-3.4c0.7-1,2.1-3.8,2.8-5c0.7-1.2,0.8-7.8,0.9-8.6c0.1-0.8-1.3-1.7-2.3-2.5
	c-1-0.8-3.4-0.8-4.1-0.8c-0.6,0-1.6,2.1-1.2,2.7c0.4,0.6,0,0.9-0.7,1.1c-0.8,0.2-2.6-1.2-3.2-1.6c-0.6-0.5-3.2-0.2-4-0.5
	c-0.8-0.2-3.3,0-3.9,0c-0.6,0-1,0.2-1.1,0.4c-0.1,0.1-0.6,0.1-0.7,0c-0.1-0.2-0.7-0.3-1.5-0.1c-0.7,0.2-2.2,5.4-2.5,6.1
	c-0.3,0.7-1.6,3.9-1.8,4.5c-0.1,0.7-1.1,0.6-1.3,0.7c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.8,0.3-1.4,0.4c-0.6,0.1-1.6,0.6-2.2,0.7
	c-0.6,0.1-2.3-0.3-3.1,0c-0.8,0.4-1.6-0.2-2.5-0.2c-1,0-2,1.5-2.5,1.9c-0.5,0.5-2.7,3-3.7,3.7c-1,0.6-3.9,3.9-4.2,4.4
	c-0.4,0.5-1,0.7-1.6,0.5c-0.7-0.2-4.2-0.2-5.5-0.2c-0.2,0-0.5-0.1-0.8-0.4c-1.3-1-2.9-3.8-2.9-3.8c0-0.2,3.8-5.6,4.8-7.1
	c1.1-1.6,0.3-3.4,0-4.3c-0.2-0.9-0.8-3.7-0.9-4.8c-0.1-1.1-4.3-4-5-4.2c-0.7-0.3-0.5-2.3-0.5-3.1c0-0.8,1.9-1.2,3-1.2
	c1.1,0,1.3-0.5,1.5-1.9c0.1-1.4-3-4-3-4c1.1-1.1,1.8-1.9,3.5-2c1.9-0.1,9-0.4,10.5-1.7c1.6-1.3,3.4-2.8,5.7-3.2
	c2.3-0.4,7.6-1.7,7.7-3.3c0.1-1.6,0-3,0.7-3.9c0.7-0.9,0.2-1.2,0.7-1.8c0.4-0.6,1.4-1.9,1.4-3.5c0-1.6,0.5-4.9,0.7-5.9
	c0.2-1,0.5-2.7-0.6-2.8c-1-0.1-4.9-0.7-4.9-1.2c0-0.6,1.5-7.1,1.5-7.1s0.4-0.9-0.5-1.4c-0.9-0.5-4.7-3.5-4.7-3.5s-0.5-0.3-0.1-1.1
	c0.4-0.7,6.8-11.9,6.8-11.9l9.7,10.9l4.2-7c0,0,0.7-0.8-0.3-1.8c-1-1-3.4-3-2.4-3.9c1-0.9,1.9-1.9,3.4-1.9c1.4,0,2.1-0.1,2.9-0.7
	c0.8-0.6,5.4-3.1,5.3-5.3c-0.1-2.2-0.2-3.1-0.9-3.8c-0.6-0.7-2.4-2.5-1.6-3.6c0.8-1.1,4.5-5.9,4.8-8.2c0.4-2.3,1.5-5,1.7-5.7
	c0.2-0.7,1.6-3.8,1.7-4.9c0.1-1.1-0.1-3.2-0.1-3.2c0.3,0,0.6-0.1,1,0c0.2,0,0.5,0.1,0.7,0.1c0.3,0,0.6,0,0.9,0c0.3,0,0.7,0,1,0.1
	c0.7,0.2,1.4,0.6,2.2,0.8c0.9,0.2,1.6,0.7,2.4,1.1c0.2,0.1,0.3,0.1,0.4,0.1c0.2,0,0.4,0.1,0.6,0.2c0.4,0.1,0.7,0.2,1.1,0.2
	c0.5,0,1,0,1.4,0c0.5,0,0.9-0.1,1.2-0.4c0.4-0.3,0.6-0.7,0.9-1.2c0.5-0.8,1.1-1.4,2-1.7c0.3-0.1,0.8-0.2,1.1-0.1
	c0.4,0.2,0.4,0.7,0.5,1c0.2,0.5,0.6,0.5,1.1,0.4c0.6-0.1,1.1-0.4,1.7-0.4c1,0.1,1.3,1.1,1.4,2c0.1,1.4,0.2,2.8,0,4.2
	c-0.1,0.6-0.1,1.1-0.1,1.7c0.1,0.6,0.1,1.2,0.2,1.8c0.1,0.4,0.1,0.7,0.4,0.9c0.3,0.2,0.7,0.4,1.1,0.4c0.4-0.1,0.7-0.4,1.1-0.6
	c0.6-0.4,1.3-0.7,2.1-0.5c0.2,0,0.5,0.2,0.7,0.4c0.3,0.2,0.5,0.5,0.8,0.8c0.2,0.2,0.4,0.5,0.5,0.8c0.3,0.6,0.6,1.1,0.7,1.7
	c0.1,0.5,0.6,0.5,0.9,0.9c0.4,0.4,0.2,1,0.4,1.4c0.1,0.2,0.2,0.5,0.4,0.6c0.2,0.2,0.4,0.2,0.7,0.2c0.3,0,0.6,0,0.8,0
	c0.3,0,0.5,0.1,0.8,0.1c0.3,0,0.6,0,0.9,0h0.4c0.2,0,0.3,0.1,0.5,0.1c0.5,0.1,0.9,0.5,1.4,0.7c0.2,0.1,0.5,0.2,0.7,0.3
	c0.2,0.1,0.5,0.2,0.7,0.2c0,0,0.7,0.3,0.8,0.7c-0.4,0.2-0.7,0.5-0.6,1.1c0.2,1.7,1.2,4.6,2.1,5c0.9,0.4,2,1.1,2.7,2.1
	c0.7,1,2.4,2.6,2.4,3.8c0,1.2,0.7,4.4,1.2,5c0.5,0.6,3,5,4.8,6.4c0,0,0.5,2.6-0.4,3c-0.9,0.4-1.1,0.2-0.9,1.3c0.1,1,1.7,7.3,1.6,9.1
	c-0.1,1.8-1.2,6.3-0.6,7.5c0.6,1.3,2.4,5.4,2.3,7.5c-0.1,2.1,0.2,5.1-0.7,6.6c-1,1.5-4.7,7.6-2.6,7.9c2.1,0.3,3.2,0.4,3.3,2.1
	c0,1.7,0.5,5.3,0.5,5.3s-6.2,7.1-5.6,9c0.7,1.8,4.4,9,5.6,9.8C299.1,251.8,300.7,253,300.6,253.9z">
        <title>Guarda</title>
    </path>
	{CircledNumber}
	</g>
            )
    }
}