import React from 'react';
import { District } from '../district';

export class Evora extends District {
	constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Évora', props.data)
		}
	}

    render () { 
		let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="190" cy="500" r="15" fill="#187795" />
				<text x="190" y="500"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
			<g>
<path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('evora') } name="évora" d="M267.4,560.7c-1.3,1.1-3.9,1.7-4.6,1.9c-0.9,0.3-1.5,1.7-1.8,2.6c-0.4,0.9-0.6-1.4-0.9-1.7
		c-0.3-0.3-0.6-1-0.5-1.9c0-1-0.2-0.8-1-1.5c-0.8-0.7-0.5-2.3-0.3-3.6c0.2-1.3-2-2-3.3-2.6c-1.3-0.6-5.4-2.3-6.7-2.6
		c-1.3-0.3-0.6-4.5-0.5-5.1c0.1-0.6-0.6-1.3-1-1.5c-0.4-0.2-1-1.1-1.8-1.8c-0.8-0.8-1.5-0.3-2.4,0c-0.9,0.3-2.2,1-3.4,1.3
		c-1.1,0.3-2.4,0.9-2.9,1.2c-0.5,0.3-0.8,1.1-1.1,1.5c-0.3,0.4-1.6,2.1-2,2.6c-0.5,0.5-1.2,2-1.8,2.8c-0.6,0.8-1.6,1.5-2.4,1.8
		c-0.8,0.3-1.5,0.7-2.9,1.6c-1.3,0.8-4.5,2.8-6,3.8c-1.6,1-2.3,0.6-4.5,0.7c-2.2,0.1-3,0.2-4.1,0.5c-1.1,0.3-3.7-0.2-4.7-0.5
		c-0.9-0.3-5.1-1.5-7.5-1.9c-2.4-0.4-6.9-3.8-7.7-4.3c-0.8-0.5-3.1-2.2-3.8-2.3c-0.7-0.1-1.7,0.4-2.5,0.8c-0.8,0.5-13-7.8-14.2-8.6
		c-1.2-0.8-2.2-1.5-4.3-2c-2.1-0.5-2.4,1.1-3.2,1.7c-0.8,0.6-3.3-1.6-4-2.3c-0.7-0.7-10.1-0.4-10.1-0.4c0.2,0.3,0.4,0.6,0.4,0.9
		c-8.1-2-15.5-14-15.5-14c1-8,0.6-13.6-0.1-17.4c-0.2-1.3-0.5-2.3-0.8-3.2c-0.5-1.7-1-2.5-1-2.5c0,0-0.1,0.1-0.1,0.1
		c-0.2,0.2-0.4,0.4-0.6,0.6c-4.4,4.1-10.4-1.6-10.4-1.6l-2.7,1.8c-18.2,3.3-11.3-9.3-11.8-11c-0.6-1.8,7.4-3.9,13.3-1.2
		c3.4,1.5,5.1,1.3,5.9,0.5c0.3-0.4,0.5-0.9,0.4-1.4c0-0.3-0.1-0.7-0.3-1c-0.9-1.9-1.5-2.5-2.4-6.5c-0.9-3.9-0.6-5.8-3.7-5.8
		c-0.1,0-0.1,0-0.2,0c-0.5,0-0.9,0-1.3-0.1c-2.5-0.3-3.8-1.3-4.4-2.6c-0.3-0.9,0.4-2.7,1.1-4c0.2-0.4,0.4-0.7,0.5-1
		c0.3,0.1,0.5,0.2,0.7,0.3c0.4,0.1,0.7,0.1,0.8,0c0.3-0.7,0.5-1.4,1.2-2c0.7-0.6,1.2-1.1,0.8-1.8c-0.4-0.7-1.4-2.8-0.9-2.9
		c0.5-0.1,1.8-0.7,2.6-1c0.7-0.3,2.7-0.3,3-0.7c0.4-0.4,0.7-0.7,1.9-0.8c1.1-0.1,8.5-0.7,9.7,0c1.2,0.6,7.6,3.7,8.7,5.3
		c1.1,1.7,4.4,7.2,5,6.7c0.6-0.4,1.7-2,2.4-2.3c0.7-0.3,0.6-0.8,0.3-1.4c-0.3-0.6-5-7.4-4.6-8.1c0.4-0.7,1-1.4,0.8-2.2
		c-0.2-0.8,0.1-1-0.7-1.1c-0.9-0.1-6.7-2.7-8.6-3.4c-2-0.8-1.2-2-1-2.4c0.3-0.4,3.7-7.8,4.1-8.6c0.3-0.6,1.5-1.9,1.4-2.9
		c1.1-0.6,2.7-2.3,3.6-3.3c1-1,5.9,0,7,0c1.1,0,0.4,2.9,0.3,4c0,1.1,1.9,3.8,1.9,3.8s2.8-5.3,3.1-6.4c0.3-1.1,1.2-0.1,1.9,0.6
		c0.8,0.7,4.6,3.3,5.9,4.1c1.4,0.8,1.1,1.7,1,4.1c-0.1,2.4,4.5-0.2,5.9-0.5c1.5-0.3,0.9,0.1,2.7,0.7c1.8,0.6,2.2,3.4,2.5,4.3
		c0.4,0.9,0.9,0.8,1.6,1.1c0.7,0.3,3.4,0.9,5.2,1.5c1.8,0.6,2.5,0.5,4.8,0.7c2.3,0.2,3.6-2.5,4.5-3.5c0.9-0.9,1.6-0.1,2,0.6
		c0.5,0.6,2,1.4,2.5,1.4c0.6,0,0-2,0.5-2.6c0.5-0.6,1.5,0.3,2,0.5c0.6,0.3,1.4,1.4,3.3,1.6c1.9,0.1,2.3-2.2,2.5-3.2
		c0.2-1.1,4.1-1.5,6-1.7c1.9-0.2,2.3-1.6,2.8-2.7c0.6-1.1-1.1-2.6-1.7-3.4c-0.5-0.8,1.6-1.5,2.7-2c1.1-0.5,3.5,1.4,4.5,2
		c0.9,0.5,2.4,5.8,2.8,6.9c0.3,1.1,1.7,2.9,3.3,5.4c1.6,2.5,1.3,2.7,1.8,4.1c0.5,1.4,0.6,3.7,0.5,5.9c-0.1,2.2,0.8,3.4,2.9,3.6
		c2,0.2,3.3,1.6,4.6,3.5c1.2,2,6.6-1.2,8.1-2.3c1.5-1.2,3.7-1.2,5.4-1.2s2.4,3.3,2.4,3.3l-5.7,5.5l4.2,4.1c-0.6,0.3-1,0.8-1.3,1.6
		c-0.9,2.1-2,4.6-1.8,6.4c0.1,1.8,1.1,9.4-0.9,11.4c-1.9,2-3.5,4-3.5,6.1c0,2,0.4,4.6,0,5.7s-0.4,1.5-0.3,2.6c0,1.1,0.8,3-0.1,3.9
		c-0.9,0.9-2.2,1.3-2,2.4c0.1,1-0.4,2.9,0.6,2.9c0.9-0.1,2.3-0.1,2.7,0.9c0.4,1,2.9,6,4.3,7.5c1.4,1.5,9.9,13.2,10,13.3
		c0.1,0,4,3.2,4.2,4.5C264.7,552.7,266.4,559,267.4,560.7z"><title>Évora</title></path>
		{CircledNumber}
		</g>
            )
    }
}