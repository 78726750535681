import React from 'react';
import { District } from '../district';

export class VianaDoCastelo extends District {
    constructor(props) {
		super(props);
		this.state = {
			numberOfPersons: this.numberOfPersons('Viana do Castelo', props.data)
		}
    }
    
    render () {
        let CircledNumber;
		if(this.state.numberOfPersons) {
			CircledNumber = <React.Fragment>			
				<circle cx="120" cy="35" r="15" fill="#187795" />
				<text x="120" y="35"  stroke="#000" strokeWidth="1px" dy=".3em" textAnchor="middle">{this.state.numberOfPersons}</text>
			</React.Fragment>
		}
        return(
            <g>
        <path className={`st0 ${!this.state.numberOfPersons ? "disabled" : ""}`} onClick={ () => this.clicked('viana do castelo') }  name="vianadocastelo" d="M159.2,17.3l-6.8,3l0.1,3.7l-6.3,6.3l2.7,4.9l3.1,0.4l1,9.3l-1.6,1.1c-0.1,0-0.4,0.2-0.5,0.3
        c-0.2,0.1-0.3,0.2-0.5,0.4c-0.5,0.3-1.2,0.4-1.7,0.5c-0.3,0.1-0.6,0.2-1,0.3c-0.2,0.1-0.5,0.2-0.7,0.4c-0.2,0.2-0.5,0.2-0.7,0.4
        c-0.3,0.2-0.5,0.3-0.7,0.5c-0.4,0.2-0.8,0.4-1.2,0.5c-0.3,0.1-0.6,0.2-0.9,0.4c-0.5,0.3-1.1,0.7-1.6,1.1c-0.4,0.4-0.7,0.8-1.1,1.1
        c-0.4,0.2-0.6,0.5-1,0.7c-0.3,0.2-0.7,0.5-1.1,0.5c-0.2,0-0.4,0-0.5-0.1c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0-0.1,0
        c-0.2-0.1-0.3-0.2-0.5-0.3h-0.6c-0.3,0-0.6-0.1-0.8-0.2c-0.4-0.1-0.8-0.1-1.2-0.1c-0.3,0-0.6,0-0.8,0c-0.5,0-1-0.2-1.5-0.3
        c-0.4-0.1-0.7-0.2-1.1-0.1c-0.5,0-0.9,0-1.3,0.2c-0.5,0.2-0.9,0.5-1.3,0.8c-0.4,0.2-0.8,0.1-1.1,0.4c-0.4,0.3-0.7,0.6-1.1,0.9
        c-0.4,0.3-1,0.4-1.5,0.6c-0.5,0.2-1,0.2-1.5,0.4c-0.2,0-0.5,0-0.6,0c-0.3,0-0.5,0.1-0.8,0.1c-0.7,0.1-1.5,0-2.2,0.1
        c-0.7,0.1-1.2,0.4-1.8,0.7c-0.7,0.4-1.4,0.7-2.1,1.1c-0.6,0.4-1,0.8-1.1,1.5c-0.1,0.6,0,1.2,0.1,1.8c0,0.5-0.1,1-0.4,1.4
        c-0.2,0.5-0.4,0.9-0.5,1.4c-0.1,0.4-0.1,0.7-0.4,1.1c-0.3,0.4-0.6,0.8-0.7,1.3c-0.1,0.5,0,0.9,0.2,1.3c0.2,0.4,0.4,0.8,0.5,1.3
        c0.1,0.5-0.1,0.8-0.5,1.1c-0.4,0.4-0.8,0.6-1.2,0.9c-0.4,0.2-0.7,0.6-1,1c-0.2,0.4-0.5,0.7-1.1,0.7c-0.6,0-1.3-0.2-1.9-0.4
        c-0.7-0.3-1.3-0.8-1.9-1.3c-0.7-0.5-1.4-1-2.2-1.2c-0.2-0.1-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.3-0.6-0.4c-0.5-0.2-1-0.2-1.5-0.2
        c-0.2,0-0.5,0.1-0.8,0.1c-0.2,0-0.5,0-0.7,0.1c-0.4,0.1-0.7,0.1-1,0.3c-0.3,0.1-0.6,0.2-0.9,0.2c-0.2,0-0.3,0-0.4,0.1
        c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.4c-0.2,0.2-0.4,0.4-0.6,0.6
        c-0.4,0.5-0.7,1.1-1.2,1.5c-0.2,0.2-0.4,0.4-0.6,0.6c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.2,0-0.3,0c-0.3,0-0.7,0.1-0.9,0
        c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2-0.1-0.4-0.4-0.6-0.5c-0.2-0.1-0.4-0.2-0.6-0.4c-0.4-0.4-0.7,0-0.8,0.5
        c0,0.2,0,0.5-0.2,0.7c0,0.1-0.2,0.2-0.2,0.3c-0.2,0.2-0.5,0.2-0.8,0.2c-0.4,0-0.7,0-1.1,0c-0.3,0-0.7-0.1-1-0.1
        c-0.2,0-0.5-0.1-0.7-0.2c-0.1,0-0.3,0-0.3-0.1c-0.2-1-0.4-1.7-0.4-1.7c-0.1-0.6-0.3-1.3-0.2-2c0-0.2,0.1-0.5,0.2-0.7
        c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.6-0.1-1.2-0.4-1.8c-0.1-0.2-0.2-0.6-0.4-0.8c-0.1-0.2-0.4-0.4-0.6-0.6c-0.2-0.1-0.4-0.4-0.4-0.7
        c0-0.3,0.2-0.5,0.4-0.6c0.6-0.3,1.2-0.7,1.9-0.7c0.9,0,1.7,0.3,2.5,0.2c0.8,0,1.4-0.6,2-1c0.7-0.4,1.4-0.5,2.1-0.8
        c0.4-0.2,0.7-0.5,1.2-0.7c-0.9,0-1.8,0-2.7,0.1c-1.2,0.1-2.4,0.3-3.5,0.6c-0.8,0.3-1.5,0.7-2.2,1.1c-0.5,0.2-0.9,0.3-1.3,0
        c-0.5-0.4-0.8-0.8-1.2-1.2c-0.5-0.6-1-1.2-1.3-1.9c-0.3-0.7-0.4-1.5-0.5-2.3c-0.1-0.7-0.2-1.3-0.5-1.9c-0.4-0.8-1-1.4-1.4-2.2
        c-0.2-0.5-0.1-1,0-1.5c0.1-0.5,0.5-0.8,0.7-1.2c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.4,0.4-0.8,0.3-1.2c0-0.4-0.3-0.7-0.5-1.1
        c-0.1-0.4-0.2-0.7-0.3-1.1c-0.2-0.7-0.2-1.6-0.2-2.3c0-0.4,0-0.8,0.1-1.2c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.2,0.1-0.4,0.2-0.7
        c0.2-0.5,0.6-0.7,1.1-1c0.5-0.2,0.9-0.4,1.4-0.7c0.9-0.5,1.7-1.1,2-2.2c0.3-1,0.9-2.2,1.9-2.7c0.4-0.2,0.8-0.2,1.2-0.3
        c0.3-0.1,0.6-0.2,0.9-0.2c0.2,0,0.4,0,0.6-0.1c0.4-0.1,0.6-0.5,0.8-0.9c0.2-0.4,0.4-0.6,0.6-1c0.3-0.3,0.6-0.7,1-1
        c0.5-0.3,1.1-0.4,1.5-0.8c0.2-0.1,0.3-0.3,0.4-0.4c0.2-0.2,0.5-0.4,0.6-0.6c0.2-0.2,0.4-0.5,0.5-0.8c0,0,0.7-0.9,1-2.6
        c0.3-1.8,0.2-3.4,2.3-4c2.1-0.5,6.2-0.7,6.7-3.4c0.5-2.6,0.6-5,2.4-4.8c1.7,0.1,3.8,0.6,5.9-0.9c2.2-1.5,6.2-4.3,7.4-4.1
        c1.2,0.2,4.3,2.2,7.1,1.4c2.8-0.7,5.8-1.4,7-1.6c1.2-0.2,2.3-0.4,3-1.2c0.6-0.9,0.7-2.1,2.6-3.2c1.9-1.2,10.8-6.5,10.8-4.2
        c0,2.3-0.9,8,0,8.5c0.9,0.5,1.7,1.3,2.8,0.6c1.1-0.7,2.5-2.1,3.3-1.6c0.7,0.5,3.6,3.4,3.6,3.4V17.3z">
            <title>Viana do Castelo</title>
        </path>
        {CircledNumber}
        </g>
        )
    }
}